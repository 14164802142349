<script setup lang="ts">
import { SavedPrompt, Skills } from "@/types";
import {
  File,
  Brain,
  Globe,
  MessageSquare,
  Ellipsis,
  PencilLine,
} from "lucide-vue-next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import Button from "../ui/button/Button.vue";
import { parseMessage } from "@/lib/messageFormater";
import { getSkillColor } from "@/services/skill";

const props = defineProps<{
  selectedSavedPrompt: SavedPrompt | null;
  isEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  savedPrompt: SavedPrompt;
  handlePromptClick: (savedPrompt: SavedPrompt) => void;
  loadSavedPrompts: () => void;
}>();

const emit = defineEmits<{
  (e: "update:selectedSavedPrompt", value: SavedPrompt | null): void;
  (e: "update:isEditDialogOpen", value: boolean): void;
  (e: "update:isDeleteDialogOpen", value: boolean): void;
}>();

function handleDeletePromptClick() {
  emit("update:selectedSavedPrompt", props.savedPrompt);
  emit("update:isDeleteDialogOpen", true);
}

function handleEditPromptClick() {
  emit("update:selectedSavedPrompt", props.savedPrompt);
  emit("update:isEditDialogOpen", true);
}
</script>

<template>
  <div
    class="group/card relative p-2 flex justify-between items-center gap-2 rounded-md bg-primary10 truncate cursor-pointer hover:bg-primary20"
  >
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger as-child>
          <div
            @click="props.handlePromptClick(props.savedPrompt)"
            class="w-3/4 flex items-center gap-2"
          >
            <div
              class="h-fit flex items-center p-2 justify-center rounded-full shrink-0 text-xs"
              :class="getSkillColor(props.savedPrompt.skill_name)"
            >
              <File
                v-if="
                  props.savedPrompt.skill_name === Skills.CHAT_WITH_DOCUMENTS
                "
                class="w-3 h-3"
              />
              <Brain
                v-else-if="
                  props.savedPrompt.skill_name === Skills.CHAT_WITH_LLM
                "
                class="w-3 h-3"
              />
              <Globe
                v-else-if="
                  props.savedPrompt.skill_name === Skills.CHAT_WITH_WEB
                "
                class="w-3 h-3"
              />
              <PencilLine
                v-else-if="props.savedPrompt.skill_name === Skills.O1_CHAIN"
                class="w-3 h-3"
              />
              <MessageSquare v-else class="w-3 h-3" />
            </div>

            <div class="ml-2 flex flex-col gap-1 w-full">
              <p class="font-medium overflow-hidden text-ellipsis">
                {{ props.savedPrompt.name }}
              </p>
              <p
                v-if="props.savedPrompt?.description"
                class="text-xs text-muted-foreground overflow-hidden text-ellipsis"
              >
                {{ props.savedPrompt.description }}
              </p>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent
          class="max-w-xs max-h-72 overflow-auto p-4 leading-6"
          :side-offset="10"
        >
          <h1 class="text-primary">
            {{ props.savedPrompt.name }}
          </h1>
          <p
            v-if="props.savedPrompt?.description"
            v-html="parseMessage(props.savedPrompt.description)"
            class="pt-2"
          ></p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

    <div
      class="opacity-0 pointer-events-none group-hover/card:opacity-100 group-hover:pointer-events-auto transition-opacity duration-200"
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            class="h-fit shrink-0 p-1 rounded-lg bg-gray-200 flex items-center"
          >
            <Ellipsis class="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem @click="handleEditPromptClick"
            >Edit</DropdownMenuItem
          >
          <DropdownMenuItem @click="handleDeletePromptClick"
            >Delete</DropdownMenuItem
          >
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </div>
</template>
