const commercialProposalPlaceholder = `Example:
"I need a business offer for a web service:
- Offer name: Essential Web Design Package.
- Target audience: Small businesses.
- Services: 5 pages website, custom design, SEO, mobile-friendly.
- Price: From €1,500 (excl. VAT).
- Delivery: 3 weeks.
- Extras: 3-months support.
- Tone: Professional, simple, transparent.
- Goal: Encourage clients to contact us."'`;

const rexPlaceholder = `Example:
"I need to write an experience feedback report:
- Client sector: Financial Services
- Mission scope: Digital Transformation Strategy
- Duration: 4 months (Jan-April 2024)
- Team structure: 2 senior consultants, 3 analysts
- Key deliverables: Strategy roadmap, Process optimization
- Challenges: Change resistance, Legacy systems
- Success factors: Strong C-level support, Agile methodology
- Goal: Document best practices for similar missions"`;

const investmentMemorandumPlaceholder = `Example:
"I need an investment memorandum for a tech startup seeking funding:
- Company name: InnovateAI.
- Objective: Raise €2 million in Series A funding.
- Use of funds: Expand R&D, scale marketing, and enter new markets (Europe and North America).
- Business model: SaaS platform with monthly subscriptions starting at €500.
- Market opportunity: AI market growing 35% annually, targeting €10 billion by 2025.
- Competitive advantage: Proprietary AI technology and 3 patents pending.
- Financials: €500,000 annual revenue, 200% YoY growth, 70% gross margin.
- Goal: Attract investors and secure funding."'`;

const pitchDeckAnalysisPlaceholder = `Example:
"I need a pitch deck analysis for a startup presentation:
- Company name: GreenTech Solutions.
- Objective: Assess the clarity and impact of the pitch deck.
- Key sections to analyze: Problem, solution, market opportunity, business model, financials, and team.
- Focus areas: Visual design, storytelling, and data presentation.
- Strengths: Clear problem statement and innovative solution.
- Weaknesses: Lack of detailed financial projections and competitive analysis.
- Recommendations: Add market validation data, refine financials, and improve slide design consistency.
- Goal: Help the startup improve their pitch to attract investors."'`;

const mAndASynergiesPlaceholder = `Example:
"I need an analysis of M&A synergies for a potential acquisition:
- Companies involved: TechCorp (acquirer) and DataSolutions (target).
- Objective: Identify and quantify synergies from the merger.
- Key areas of synergy: Cost savings (operations, procurement), revenue growth (cross-selling, market expansion), and technology integration.
- Estimated synergies: €10 million annual cost savings and €5 million additional revenue within 3 years.
- Challenges: Integration of IT systems and alignment of company cultures.
- Opportunities: Strengthened market position and enhanced product offering.
- Recommendations: Develop a detailed integration plan and monitor synergy realization.
- Goal: Provide a clear view of the value created by the merger."'`;

const executiveSummaryPlaceholder = `Example:
"I need to write an executive summary for a consulting project:
- Client: Global Insurance Group
- Context: Market share decline in digital channels
- Objective: Define 3-year digital transformation roadmap
- Key findings: 40% process inefficiency, outdated tech stack
- Recommendations: Cloud migration, AI implementation
- Expected benefits: €15M cost reduction, 25% efficiency gain
- Implementation: 18-month program, €5M investment
- Goal: Obtain C-level buy-in for transformation program"`;

const meetingMinutesPlaceholder = `Example:
"I need to write meeting minutes for a consulting project:
- Meeting type: Steering Committee
- Participants: Client CIO, Program Director, 3 Partners
- Project status: Digital transformation - Phase 2
- Key discussions: Budget review, Risk assessment
- Decisions made: Additional resources approved, Timeline adjusted
- Action items: Security audit by June, Weekly progress reports
- Next steps: Technical workshop scheduled for next week
- Goal: Track and communicate project governance decisions"`;

const cvUpdatePlaceholder = `Example:
"I need to update my CV with my latest PMO experience:
- Current role: Program Management Officer at Global Bank
- Duration: Jan 2023 - Present
- Project scope: Digital transformation program (€50M budget)
- Key responsibilities: Stakeholder management, Risk monitoring, KPI tracking
- Skills developed: Agile methodologies, Executive reporting, ...
- Tools mastered: Jira, PowerBI, MS Project
- Previous experience: 3 years as Management Consultant at Big4
- Goal: Highlight project management expertise and consulting skills"`;

const documentTypePlaceholders = {
  "Commercial Proposal": commercialProposalPlaceholder,
  "REX (Experience Feedback)": rexPlaceholder,
  "Executive Summary": executiveSummaryPlaceholder,
  "Meeting Minutes": meetingMinutesPlaceholder,
  "Investment Memorandum": investmentMemorandumPlaceholder,
  "Pitch Deck Analysis": pitchDeckAnalysisPlaceholder,
  "M&A Synergies": mAndASynergiesPlaceholder,
  "CV Update": cvUpdatePlaceholder,
};

export default documentTypePlaceholders;
