import App from "@/App.vue";
import router from "@/router";
import "@/style.css";
import descope from "@descope/vue-sdk";
import { createPinia } from "pinia";
import { createApp } from "vue";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(descope, {
  projectId: import.meta.env.VITE_DESCOPE_PROJECT_ID || "",
  baseUrl: "",
  sessionTokenViaCookie: true,
});

app.mount("#app");
