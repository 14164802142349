import { useTenantStore } from "@/stores/tenant";
import CatalogManagementView from "@/views/CatalogManagementView.vue";
import ChatView from "@/views/ChatView.vue";
import DataSourcesView from "@/views/DataSourcesView.vue";
import DataSourceView from "@/views/DataSourceView.vue";
import UnauthorizedView from "@/views/UnauthorizedView.vue";
import UserManagementView from "@/views/UserManagementView.vue";
import UserProfileView from "@/views/UserProfileView.vue";
import {
  CircleUser,
  Database,
  LibraryBig,
  MessageSquare,
  Users,
} from "lucide-vue-next";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "./stores/auth";
import NotFoundView from "./views/NotFoundView.vue";

type tenantStoreType = ReturnType<typeof useTenantStore>;
export const routes: RouteRecordRaw[] = [
  {
    path: "/chat/:session_id?",
    component: ChatView,
    name: "Chat",
    meta: {
      icon: MessageSquare,
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: false,
    },
  },
  {
    path: "/data-sources",
    component: DataSourcesView,
    name: "Data sources",
    meta: {
      icon: Database,
      canAccess: () => true,
      showInSidebar: true,
      showInUserMenu: false,
    },
  },
  {
    path: "/data-sources/:id",
    component: DataSourceView,
    name: "DataSource",
    meta: {
      icon: Database,
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: false,
    },
  },
  {
    path: "/user",
    component: UserProfileView,
    name: "User profile",
    meta: {
      icon: CircleUser,
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: true,
    },
  },
  {
    path: "/catalog-management",
    component: CatalogManagementView,
    name: "Catalog management",
    meta: {
      icon: LibraryBig,
      canAccess: (tenantStore: tenantStoreType) =>
        tenantStore.tenantName === "Elqano",
      showInSidebar: true,
      showInUserMenu: false,
    },
  },
  {
    path: "/user-management",
    component: UserManagementView,
    name: "User management",
    meta: {
      icon: Users,
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: true,
    },
  },
  {
    path: "/unauthorized",
    component: UnauthorizedView,
    name: "Unauthorized",
    meta: {
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundView,
    name: "NotFound",
    meta: {
      canAccess: () => true,
      showInSidebar: false,
      showInUserMenu: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const tenantStore = useTenantStore();
  const auth = useAuthStore();

  try {
    // Attendre que les données utilisateur soient chargées
    await auth.userDataLoadedPromise;

    if (to.path === "/") {
      auth.microsoftRedirectHash = to.hash;
      next("/chat");
      return;
    }

    const canAccess = (
      to.meta.canAccess as (tenantStore: tenantStoreType) => boolean
    )(tenantStore);

    if (canAccess) {
      next();
    } else {
      next("/unauthorized");
    }
  } catch (error) {
    console.error("Error in router guard:", error);
    next("/unauthorized");
  }
});

export default router;
