<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import FlowAccordionActionButtons from "@/components/ChatView/flow/FlowAccordionActionButtons.vue";
import { promptAnswers, sendMessage, sendPrompt } from "@/services/websocket";
import { useTenantStore } from "@/stores/tenant";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import {
  getFlowPrompts,
  updatePromptAnswer,
  updatePromptsFlowAnswer,
} from "@/services/flow_prompts";
import { parseMessage } from "@/lib/messageFormater";
import { DataSource, Skills } from "@/types";
import { Loader2 } from "lucide-vue-next";
import FlowAnswerTemplatePickerModal from "./FlowAnswerTemplatePickerModal.vue";

export type Prompt = {
  id: string;
  title: string;
  content: string;
  answer: string | undefined;
  is_archived: boolean;
  skill_name: string;
  data_sources: DataSource[];
};

export type Flow = {
  id: string;
  final_answer: string;
  prompts: Prompt[];
};

type Props = {
  flowId: string;
};

const { flowId } = defineProps<Props>();
const tenantStore = useTenantStore();
const authStore = useAuthStore();
const route = useRoute();

const prompts = ref<Prompt[]>([]);
const flow = ref<Flow>({
  id: "",
  final_answer: "",
  prompts: [],
});

const sessionId = ref<string | undefined>(
  route.params.session_id as string | undefined
);
const tenantId = ref<string>(tenantStore.tenantId || "");
let finalAnswerOn = ref<boolean>(false);

const isAnyAnswerStored = computed(() => {
  return prompts.value.some((prompt) => prompt.answer);
});

const isTemplatePickerDialogOpen = ref<boolean>(false);

const loadingEffect = (prompt: Prompt) => {
  if (!promptAnswers[prompt.id] && !prompt.answer) {
    return "text-placeholder";
  } else if (promptAnswers[prompt.id] && !prompt.answer) {
    return "shimmer-text animate-shimmer";
  } else {
    return "";
  }
};

const parsingMessage = (prompt: Prompt) => {
  return parseMessage(
    prompt.answer ||
      (promptAnswers[prompt.id] && promptAnswers[prompt.id].content) ||
      ""
  );
};

async function handleGeneratePromptAnswers() {
  const token = await authStore.aquireToken();
  if (!tenantId.value) return;

  prompts.value.forEach((prompt) => {
    const dataSourcesIds = prompt.data_sources.map((ds) => ds.id);

    promptAnswers[prompt.id] = "";
    sendPrompt(
      prompt.content,
      prompt.id,
      tenantId.value,
      sessionId.value || "",
      prompt.skill_name,
      dataSourcesIds,
      token,
      authStore.isInsideTeams
    );
  });
}

async function getFlowFinalAnswer(dataSourceId: number) {
  finalAnswerOn.value = true;
  isTemplatePickerDialogOpen.value = false;
  const token = await authStore.aquireToken();
  if (!tenantId.value || !dataSourceId) {
    finalAnswerOn.value = false;
    return;
  }

  const message =
    "Take into account all the previous messages in this conversation. Analyze them and synthesize the information to produce a complete and detailed response, ensuring that no important points are missed.";
  sendMessage(
    message,
    tenantId.value,
    sessionId.value || "",
    Skills.O1_CHAIN,
    [dataSourceId],
    token,
    authStore.isInsideTeams
  );
}

watch(
  () => promptAnswers,
  async (newVal) => {
    try {
      const tenantId = tenantStore.tenantId || "";
      for (const id of Object.keys(newVal)) {
        if (
          newVal[id].completed &&
          prompts.value.find((item) => item.id === id) //only treat prompts that are in this component
        ) {
          const content = newVal[id].content;
          const index = prompts.value.findIndex(
            (prompt) => prompt.id === String(id)
          );
          prompts.value[index].answer = content;

          delete promptAnswers[id];

          await updatePromptAnswer(tenantId, id, content);
        } else if (flowId === id && newVal[id].completed) {
          await updatePromptsFlowAnswer(tenantId, flowId, newVal[id].content);
        }
        await nextTick();
      }
    } catch (error) {
      console.error("Error updating prompt answers:", error);
    }
  },
  { deep: true }
);

onMounted(async () => {
  if (!tenantId.value) return;
  try {
    const response = await getFlowPrompts(tenantId.value, flowId);
    flow.value = response;
    prompts.value = response.prompts;
    if (!isAnyAnswerStored.value) {
      await handleGeneratePromptAnswers();
    }
  } catch (e) {
    console.error(e);
  }
});
</script>
<template>
  <FlowAnswerTemplatePickerModal
    v-model="isTemplatePickerDialogOpen"
    @onTemplateSelection="getFlowFinalAnswer"
    :tenantId="tenantId"
  />
  <div class="flow-accordion w-full flex flex-col gap-4">
    <Accordion class="flex-1 w-full" type="multiple">
      <AccordionItem
        v-for="prompt in prompts"
        :key="prompt.id"
        :value="prompt.id"
        class="w-full flex-1"
      >
        <AccordionTrigger class="flex truncate w-full">
          <h3 class="truncate" :class="loadingEffect(prompt)">
            {{ prompt.title }}
          </h3>
        </AccordionTrigger>
        <AccordionContent>
          <div class="w-full flex flex-col gap-4 bg-primary10 p-4">
            <Loader2
              v-if="!promptAnswers[prompt.id] && !prompt.answer"
              class="animate-spin h-5 w-5"
            />
            <p v-else v-html="parsingMessage(prompt)"></p>
            <FlowAccordionActionButtons :prompt="prompt" v-if="false" />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </div>
</template>
