import apiClient from "@/services/api_client";
import { useAuthStore } from "@/stores/auth";
import { useChatSessionsStore } from "@/stores/chatSessions";
import { PromptAnswers, Skills } from "@/types";
import { isSessionTokenExpired } from "@descope/vue-sdk";
import { nextTick, reactive, ref, watch, watchEffect } from "vue";
const websocket = ref<WebSocket | null>(null);
const isWebSocketOpen = ref(false);
const botResponseIndex = ref<number | null>(null);
const descopeTokenInWebsocket = ref<string | null>(null);
const messages = ref<
  Array<{
    type: string;
    content: string;
    isLoading: boolean;
    sources?: any;
    flowId?: string;
    id?: string;
    fileName?: string;
  }>
>([]);

const promptAnswers = reactive<PromptAnswers | any>({});
const isProcessingMessage = ref<boolean>(false);
const isAbleToStop = ref<boolean>(false);
const scribeProgress = ref<{ value: number; message: string }>({
  value: 0,
  message: "",
});
const isGeneratingDocument = ref<boolean>(false);
const generationStep = ref<string>("");

const waitForProcessingToFinish = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const stopWatching = watch(
      () => isProcessingMessage.value,
      (processing) => {
        if (!processing) {
          const lastMessage = messages.value[messages.value.length - 1];
          stopWatching();
          if (lastMessage?.type === "error") {
            isGeneratingDocument.value = false;
            generationStep.value = "";
            reject(new Error(lastMessage.content));
          } else {
            resolve();
          }
        }
      },
      { immediate: true, deep: true }
    );
  });
};

const waitForAllPromptsToBeCompleted = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    let lastUpdateTimestamp = Date.now();
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const stopWatching = watchEffect(() => {
      const keys = Object.keys(promptAnswers);
      const completedAnswers = keys.filter(
        (key) => promptAnswers[key].completed
      );

      // Reset timeout on any update to promptAnswers
      lastUpdateTimestamp = Date.now();
      if (timeoutId) clearTimeout(timeoutId);

      // Set new timeout
      timeoutId = setTimeout(() => {
        if (Date.now() - lastUpdateTimestamp >= 60000) {
          stopWatching();
          if (completedAnswers.length === 0) {
            isGeneratingDocument.value = false;
            generationStep.value = "";
            reject(
              new Error("No answers completed after 30 seconds of inactivity")
            );
          } else {
            resolve();
          }
        }
      }, 60000);

      // Check for errors
      const lastMessage = messages.value[messages.value.length - 1];
      if (lastMessage?.type === "error") {
        if (timeoutId) clearTimeout(timeoutId);
        isGeneratingDocument.value = false;
        generationStep.value = "";
        stopWatching();
        reject(new Error(lastMessage.content));
      }

      // Check if all prompts are completed
      if (keys.length > 0 && keys.length === completedAnswers.length) {
        if (timeoutId) clearTimeout(timeoutId);
        stopWatching();
        resolve();
      }
    });

    // Cleanup timeout on watch cleanup
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  });
};

const connectWebSocket = async (url: string) => {
  const auth = useAuthStore();
  const token = await auth.getSessionToken();
  descopeTokenInWebsocket.value = token;

  const host = apiClient.host || location.host;

  websocket.value = new WebSocket(
    `wss://${host}/api/ws/${url}?token=${token}&teams=${auth.isInsideTeams}`
  );
  setupWebSocketHandlers();
};

const disconnectWebSocket = (code: number = 1000, reason: string = "") => {
  if (websocket.value) {
    websocket.value.close(code, reason);
  }
};

const setupWebSocketHandlers = () => {
  if (websocket.value) {
    websocket.value.onmessage = handleWebSocketMessage;
    websocket.value.onopen = handleWebSocketOpen;
    websocket.value.onerror = handleWebSocketError;
    websocket.value.onclose = handleWebSocketClose;
  }
};

const handleWebSocketMessage = async (event: MessageEvent) => {
  const botMessageContent = event.data;

  try {
    const parsedMessage = JSON.parse(botMessageContent);
    switch (parsedMessage.type) {
      case "sources":
        if (messages.value.length > 0) {
          const lastMessage = messages.value[messages.value.length - 1];
          lastMessage.sources = parsedMessage.sources;
        }
        break;
      case "completion":
        if (messages.value.length > 0) {
          const lastMessage = messages.value[messages.value.length - 1];

          if (
            (lastMessage.content.trim() === "..." ||
              lastMessage.content.trim() === "") &&
            !lastMessage.sources
          ) {
            messages.value.pop();
          }
        }
        updateBotMessageId(parsedMessage.message_id);

        isProcessingMessage.value = false;
        isAbleToStop.value = false;
        break;
      case "flow_id":
        const lastMessage = messages.value[messages.value.length - 1];
        lastMessage.flowId = parsedMessage.flow_id;
        break;
      case "prompt_answer":
        promptAnswers[parsedMessage["prompt_id"]] = {
          content: parsedMessage["content"],
          completed: false,
        };
        break;
      case "prompt_answer_completed":
        promptAnswers[parsedMessage["prompt_id"]] = {
          content: promptAnswers[parsedMessage["prompt_id"]]["content"],
          completed: true,
        };
        break;
      case "error":
        // code 4000 represents a generic backend error from the client side
        disconnectWebSocket(4000);
        messages.value.pop();
        addErrorMessage(parsedMessage.content);
        break;
      case "able_to_stop":
        isAbleToStop.value = true;
        break;
      default:
        updateBotMessage(parsedMessage.content);
        break;
    }
  } catch (e) {
    console.error("Error parsing message:", e);
  }
  await nextTick();
};

const addBotMessage = (botMessage: {
  type: string;
  content: string;
  isLoading: boolean;
  sources?: any;
}) => {
  messages.value.push({
    ...botMessage,
    sources: botMessage.sources || {},
  });
  botResponseIndex.value = messages.value.length - 1;
};

const addErrorMessage = (content: string) => {
  messages.value.push({
    type: "error",
    content: content,
    isLoading: false,
  });
  botResponseIndex.value = null;
  isProcessingMessage.value = false;
};

const updateBotMessage = (content: string) => {
  if (botResponseIndex.value !== null) {
    messages.value[botResponseIndex.value].content = content;
    messages.value[botResponseIndex.value].isLoading =
      content.trim() === "..." || content.trim() === "";
  } else {
    addBotMessage({
      type: "bot",
      content,
      isLoading: content.trim() === "..." || content.trim() === "",
      sources: {},
    });
  }
};

const updateBotMessageId = (id: string) => {
  if (botResponseIndex.value !== null) {
    messages.value[botResponseIndex.value].id = id;
  }
};

const handleWebSocketOpen = () => {
  console.log("WebSocket connection established");
  isWebSocketOpen.value = true;
};

const handleWebSocketError = (error: Event) => {
  console.error("WebSocket error:", error);
};

const handleWebSocketClose = (event: CloseEvent) => {
  console.log("WebSocket connection closed");
  isWebSocketOpen.value = false;

  if (event.code !== 1000) {
    // 1000 is the normal closure code
    console.error(`WebSocket closed with code ${event.code}: ${event.reason}`);
  }
};

const waitForWebSocketConnection = (): Promise<void> => {
  return new Promise((resolve) => {
    const checkWebSocketStatus = () => {
      if (isWebSocketOpen.value) {
        resolve();
      } else {
        setTimeout(checkWebSocketStatus, 100); // Check again in 100ms
      }
    };
    checkWebSocketStatus();
  });
};

const sendMessage = async (
  messageContent: string,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false,
  hideMessage: boolean = false
) => {
  const userMessage = {
    type: "user",
    content: messageContent,
    isLoading: false,
    session_id: session_id,
    hide_message: hideMessage,
  };
  if (!hideMessage) {
    messages.value.push(userMessage);
  }
  botResponseIndex.value = null;
  isProcessingMessage.value = true;
  const botLoadingMessage = { type: "bot", content: "", isLoading: true };
  messages.value.push(botLoadingMessage);
  botResponseIndex.value = messages.value.length - 1;

  await send(
    messageContent,
    undefined,
    tenantId,
    session_id,
    skill,
    datasourceIds,
    microsoftToken,
    fromTeams,
    "user_message",
    hideMessage
  );
};

const sendPrompt = async (
  messageContent: string,
  prompt_id: string,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false,
  hideMessage: boolean = false
) => {
  botResponseIndex.value = null;
  botResponseIndex.value = messages.value.length - 1;

  await send(
    messageContent,
    prompt_id,
    tenantId,
    session_id,
    skill,
    datasourceIds,
    microsoftToken,
    fromTeams,
    "prompt",
    hideMessage
  );
};

const send = async (
  messageContent: string,
  prompt_id: string | undefined,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false,
  sendType: string = "user_message",
  hideMessage: boolean = false
) => {
  botResponseIndex.value = null;
  botResponseIndex.value = messages.value.length - 1;

  if (
    isWebSocketOpen.value === false ||
    isSessionTokenExpired(descopeTokenInWebsocket.value ?? undefined)
  ) {
    isWebSocketOpen.value = false;
    await connectWebSocket("chat");
    await waitForWebSocketConnection();
  }

  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    const messageData = JSON.stringify({
      type: sendType,
      ...(prompt_id && { prompt_id: prompt_id }),
      content: messageContent,
      session_id: session_id,
      tenantId: tenantId,
      skill: skill,
      data_source_ids: datasourceIds,
      token: { accessToken: microsoftToken, fromTeams: fromTeams },
      hide_message: hideMessage,
    });
    websocket.value.send(messageData);
  } else {
    console.error("WebSocket is not open");
  }
};

const sendStopSignal = () => {
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    const messageData = JSON.stringify({
      type: "stop",
    });
    websocket.value.send(messageData);
    console.log("Stop signal sent");
  } else {
    console.error("WebSocket is not open");
  }
};

const documentGenerator = async (
  userMessageInput: string,
  tenantId: string,
  chatSessionId: string,
  selectedDataSourcesIds: number[],
  templateDataSourceId: number,
  microsoftToken: string | undefined,
  fromTeams: boolean = false,
  documentType: string
) => {
  isGeneratingDocument.value = true;
  try {
    generationStep.value = "Step 1/3: Analyzing input, documents and context";

    const chatSessionsStore = useChatSessionsStore();
    const name = `📄 ${documentType}`;
    chatSessionsStore.updateChatSession(chatSessionId, name);

    sendMessage(
      userMessageInput,
      tenantId,
      chatSessionId,
      Skills.CHAT_WITH_DOCUMENTS,
      selectedDataSourcesIds,
      microsoftToken,
      fromTeams,
      true
    );

    await waitForProcessingToFinish();

    generationStep.value = "Step 2/3: Dig deeper in subjects and context";
    const lastMessageToDevelop =
      messages.value[messages.value.length - 1].content;

    sendMessage(
      lastMessageToDevelop,
      tenantId,
      chatSessionId,
      Skills.CREATE_FLOW,
      selectedDataSourcesIds,
      microsoftToken,
      fromTeams,
      true
    );

    await waitForAllPromptsToBeCompleted();

    generationStep.value = "Step 3/3: Generating final document";
    const message = `Take into account all the previous messages in this conversation. Analyze them carefully and synthesize the information to generate a comprehensive and detailed response. Ensure that no key points are overlooked. Your response must directly and thoroughly address the user's initial question, which is as follows: ${userMessageInput}.`;

    sendMessage(
      message,
      tenantId,
      chatSessionId,
      Skills.O1_CHAIN,
      [templateDataSourceId],
      microsoftToken,
      fromTeams,
      true
    );

    await waitForProcessingToFinish();
  } finally {
    isGeneratingDocument.value = false;
    generationStep.value = "";
  }
};

export {
  connectWebSocket,
  disconnectWebSocket,
  documentGenerator,
  generationStep,
  isAbleToStop,
  isGeneratingDocument,
  isProcessingMessage,
  isWebSocketOpen,
  messages,
  promptAnswers,
  scribeProgress,
  sendMessage,
  sendPrompt,
  sendStopSignal,
  waitForWebSocketConnection,
  websocket,
};
