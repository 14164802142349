import apiClient from "@/services/api_client";

export async function eventCopyMessage(
  messageId: string,
  tenantId: string,
  messageContent: string,
  sessionId: string
) {
  const url = "/event/copy_message";
  const body = {
    message_id: messageId,
    tenant_id: tenantId,
    message_content: messageContent,
    session_id: sessionId,
  };

  const response = await apiClient.post(url, body);
  return await response.json();
}

export async function eventExportMessage(
  messageId: string,
  tenantId: string,
  message_content: string,
  sessionId: string
) {
  const url = "/event/export_message";
  const body = {
    message_id: messageId,
    tenant_id: tenantId,
    message_content: message_content,
    session_id: sessionId,
  };

  const response = await apiClient.post(url, body);
  return await response.json();
}
