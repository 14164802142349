<script setup lang="ts">
import { SavedPrompt, Skills, Tag, Visibility } from "@/types";
import { File, Globe, Brain, MessageSquare } from "lucide-vue-next";
import { useToolbarStore } from "@/stores/toolbarStore";
import { useTenantStore } from "@/stores/tenant";
import { parseMessage } from "@/lib/messageFormater";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { createSavedPrompt } from "@/services/saved_prompts";
import { getSkillColor } from "@/services/skill";

type Props = {
  savedPrompt: SavedPrompt;
  tags: Tag[];
};

const { savedPrompt, tags } = defineProps<Props>();
const toolbarStore = useToolbarStore();
const tenantStore = useTenantStore();

const emit = defineEmits<{
  (e: "close"): void;
}>();

async function handleCreateSavedPrompt() {
  if (!tenantStore.tenantId) {
    return;
  }
  emit("close");
  await createSavedPrompt(
    savedPrompt.name,
    savedPrompt.content,
    Visibility.PRIVATE,
    tenantStore.tenantId,
    tags[0]?.id,
    savedPrompt.description,
    savedPrompt.skill_name as string
  );
  toolbarStore.setReloadSavedPrompts(true);
}
</script>

<template>
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger as-child>
        <div
          class="prompt_card relative p-8 items-center rounded-lg h-48 flex flex-col gap-4 justify-between border border-primary30 overflow-hidden bg-primary10 hover:bg-primary20 cursor-pointer transition-colors duration-200 ease-in-out hover:border-primary40 shadow-sm"
          @click="handleCreateSavedPrompt"
        >
          <div class="card_infos w-full flex flex-col gap-2">
            <div class="card_header w-full flex justify-between gap-4">
              <h2
                class="font-medium text-ellipsis overflow-hidden line-clamp-3"
              >
                {{ savedPrompt.name }}
              </h2>
              <div class="flex gap-2">
                <div
                  class="h-fit flex items-center p-2 justify-center rounded-full shrink-0 text-xs group transition-all duration-500"
                  :class="getSkillColor(savedPrompt.skill_name)"
                >
                  <File
                    v-if="savedPrompt.skill_name === Skills.CHAT_WITH_DOCUMENTS"
                    class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
                  />
                  <Brain
                    v-else-if="savedPrompt.skill_name === Skills.CHAT_WITH_LLM"
                    class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
                  />
                  <Globe
                    v-else-if="savedPrompt.skill_name === Skills.CHAT_WITH_WEB"
                    class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
                  />
                  <MessageSquare v-else class="w-4 h-4" />

                  <p
                    class="overflow-hidden opacity-0 max-w-0 transition-all duration-500 group-hover:opacity-100 group-hover:max-w-xs whitespace-nowrap"
                  >
                    {{ savedPrompt.skill_name }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="savedPrompt.description" class="card_content">
              <div
                class="text-muted-foreground text-sm text-ellipsis overflow-hidden line-clamp-3 leading-6"
                v-html="parseMessage(savedPrompt?.description)"
              ></div>
            </div>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent
        class="max-w-xl max-h-72 overflow-auto p-4 leading-6"
        :side-offset="-10"
      >
        <h1 class="text-primary text-lg mb-2">{{ savedPrompt.name }}</h1>
        <p v-html="parseMessage(savedPrompt.content)"></p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
