<script setup lang="ts">
import { computed, defineModel } from "vue";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogHeader,
} from "@/components/ui/dialog";
import TemplatePicker from "@/components/ChatView/flow/TemplatePicker.vue";

type Props = {
  tenantId: string | undefined;
};
const { tenantId } = defineProps<Props>();
const tenantIdRef = computed(() => tenantId);
const isTemplatePickerDialogOpen = defineModel<boolean>();

const emit = defineEmits<{
  (event: "onTemplateSelection", payload: number): void;
}>();

const getFlowFinalAnswer = (dataSourceId: number) => {
  emit("onTemplateSelection", dataSourceId);
  isTemplatePickerDialogOpen.value = false;
};
</script>

<template>
  <Dialog v-model:open="isTemplatePickerDialogOpen">
    <DialogContent class="flex flex-col gap-8 items-center">
      <DialogHeader class="m-auto pt-6 flex flex-col gap-3">
        <DialogTitle class="text-center">Choose a template</DialogTitle>
        <p class="text-sm text-center font-light text-secondary-foreground">
          Select a file to serve as a template for structuring the response
        </p>
      </DialogHeader>
      <DialogDescription
        class="w-4/5 flex flex-col gap-3 justify-center items-center pb-6"
      >
        <TemplatePicker
          :tenantId="tenantIdRef"
          @onSelection="getFlowFinalAnswer"
        />
      </DialogDescription>
    </DialogContent>
  </Dialog>
</template>
