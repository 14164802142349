export interface DataSource {
  id: number;
  name: string;
  tenant_id: string;
  owners: string[];
  visibility: string;
  index?: string;
  description?: string;
  chat_session_id?: string;
  count: number;
}

export interface SavedPrompt {
  id: number;
  name: string;
  content: string;
  visibility: string;
  tenant_id: string;
  author: string;
  description?: string;
  order?: number;
  skill_name?: string;
  tags?: Tag[];
}

export interface Tag {
  id: number;
  name: string;
  tenant_id: string;
}

export type SavedPromptWithMetadata = {
  prompt: SavedPrompt;
  order?: number;
  tags?: Tag[];
};

export interface ChatSession {
  id: string;
  name: string;
  is_active: boolean;
  first_visit: boolean;
}

export interface Message {
  content: string;
  type: string;
  isLoading: boolean;
  sources?: Sources; // todo - define type
  flowId?: string;
  fileName?: string;
}

export type Sources = {
  images?: ImageMetadata[];
  memory?: ChatMessage[];
  summaries?: Summary[];
  documents?: Document[];
  web_infos?: WebInfo[];
};

export type ChatMessage = {
  content: string;
  type: string;
};

export type BaseSourceType = {
  content: string;
  metadata: any;
};

export type WebInfo = BaseSourceType;
export type Document = BaseSourceType;
export type Summary = BaseSourceType;

export type ImageMetadata = {
  image_id: string;
  source: string;
  web_url?: string;
  page?: number;
};

export type MessageImage = {
  image: string;
  metadata: ImageMetadata;
};

export enum IndexingStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  INDEXED = "INDEXED",
  ERROR = "ERROR",
}

export interface Item {
  id: string;
  name: string;
  item_type: string;
  indexing_status?: IndexingStatus;
  site_id?: string;
  drive_id?: string;
  drive_item_id?: string;
  active?: boolean;
  datasource_item_id?: string;
}

export type SearchItem = {
  id: string;
  name: string;
  type: string;
  folder: boolean;
  webUrl: string;
  parentReference: {
    driveId: string;
    id: string;
    siteId: string;
    sharepointIds: any;
  };
};

export interface Folder {
  site_id?: string;
  drive_id?: string;
  drive_item_id?: string;
  items: Item[];
}

export enum Role {
  OWNER = "owner",
  READER = "reader",
  NONE = "none",
}

export enum Visibility {
  PUBLIC = "public",
  PRIVATE = "private",
}

export type OnboardingType =
  | "chat"
  | "datasource_creation"
  | "datasource_upload"
  | "saved_prompt_creation";

export type OnboardingStatus = "pending" | "done" | "skipped";

export interface OnboardingTour {
  id: number;
  user_id: string;
  tenant_id: string;
  type: OnboardingType;
  status: OnboardingStatus;
}

export interface Skill {
  name: string;
  description: string;
}

export type Notification = {
  id: string;
  user_id: string;
  tenant_id: string;
  message: string;
  unread: boolean;
  is_archived: boolean;
  created_at: Date;
  updated_at: Date;
};

export enum Skills {
  CHAT_WITH_DOCUMENTS = "Chat with documents",
  CHAT_WITH_LLM = "Chat with ChatGPT",
  CHAT_WITH_WEB = "Web search",
  O1_CHAIN = "Editorial Assistant",
  CREATE_FLOW = "Create flow",
  DOCUMENT_GENERATOR = "Document generator",
  NONE = "None",
}

export type PromptAnswer = {
  content: string;
  completed: boolean;
};

export type PromptAnswers = {
  [key: string]: PromptAnswer;
};
