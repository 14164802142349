<script setup lang="ts">
import { computed, onMounted } from "vue";
import { RouterView, useRoute } from "vue-router";
import Sidebar from "./components/Sidebar.vue";
import LoginView from "./views/LoginView.vue";
import Toaster from "@/components/ui/toast/Toaster.vue";
import Toolbar from "@/components/QuickAccessBar/Toolbar.vue";
import { useAuthStore } from "@/stores/auth";
import { useChatSessionsStore } from "./stores/chatSessions";
import "@/style/style-shepherd.css";
import "shepherd.js/dist/css/shepherd.css";

const auth = useAuthStore();

const route = useRoute();
const chatSessionsStore = useChatSessionsStore();
const showToolbar = computed(
  () => route.name === "Chat" && chatSessionsStore.showChatUX
);
onMounted(async () => {
  await auth.checkTeams();
});
</script>

<template>
  <Toaster />
  <div class="flex text-foreground bg-background" v-if="auth.isLoading"></div>
  <div
    class="flex text-foreground bg-background"
    v-else-if="auth.isAuthenticated"
  >
    <Sidebar />
    <div class="main_container relative h-screen flex-1 overflow-hidden">
      <RouterView />
    </div>
    <Toolbar v-if="showToolbar" />
  </div>
  <LoginView v-else> </LoginView>
</template>
