<script setup lang="ts">
import DataSourceSelector from "@/components/DataSources/DataSourceSelector.vue";
import SkillSelector from "@/components/SkillSelector.vue";
import { computed } from "vue";
import { Skills } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";

const toolbarStore = useToolbarStore();

const showDataSourceSelector = computed(
  () =>
    toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS ||
    toolbarStore.selectedSkill === Skills.O1_CHAIN
);
</script>

<template>
  <div class="flex gap-4 absolute top-8 left-4 z-40">
    <SkillSelector />
    <DataSourceSelector
      v-if="showDataSourceSelector"
      id="data_source_selector"
    />
  </div>
</template>
