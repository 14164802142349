import { ref } from "vue";

export function useApiRequest<T>() {
  const loading = ref<boolean>(true);
  const data = ref<T | null>(null);
  const error = ref<string | null>(null);

  const execute = async (apiCall: () => Promise<any>) => {
    loading.value = true;
    error.value = null;
    try {
      const result = await apiCall();
      data.value = result;
    } catch (err: any) {
      error.value = err.message || "An error occurred";
    } finally {
      loading.value = false;
    }
  };

  return { loading, data, error, execute };
}
