import {
  archiveChatSession as apiArchiveChatSession,
  updateChatSession as apiUpdateChatSession,
  createChatSession,
  getChatSessions,
} from "@/services/chat_sessions";
import { useTenantStore } from "@/stores/tenant";
import { ChatSession } from "@/types";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useChatSessionsStore = defineStore("chatSessions", () => {
  const chatSessions = ref<ChatSession[]>([]);
  const tenantStore = useTenantStore();
  const shouldScrollToTop = ref(false);
  const skill = ref<string>("");
  const documentToReload = ref<boolean>(false);
  const isUploading = ref<boolean>(false);
  const firstVisit = ref<boolean>(true);
  const isLoadingChatSession = ref<boolean>(true);

  const showChatUX = computed(() => {
    return !firstVisit.value && !isLoadingChatSession.value;
  });

  async function fetchChatSessions(tenantId: string) {
    chatSessions.value = await getChatSessions(tenantId);
  }

  async function addChatSession(name: string, tenantId: string) {
    const newChat = await createChatSession(name, tenantId);
    chatSessions.value.unshift(newChat);
    return newChat;
  }

  async function archiveChatSession(chatSessionId: string, tenantId: string) {
    await apiArchiveChatSession(chatSessionId, tenantId);
    chatSessions.value = chatSessions.value.filter(
      (cs) => cs.id !== chatSessionId
    );
  }

  function moveChatSessionToTop(chatSessionId: string) {
    const index = chatSessions.value.findIndex((cs) => cs.id === chatSessionId);
    if (index !== -1) {
      const [chatSession] = chatSessions.value.splice(index, 1);
      chatSessions.value.unshift(chatSession);
      shouldScrollToTop.value = true;
    }
  }
  async function markChatSessionAsVisited(
    chatSessionId: string
  ): Promise<ChatSession | undefined> {
    if (!tenantStore.tenantId) {
      return;
    }

    const chatSession = await apiUpdateChatSession(
      chatSessionId,
      tenantStore.tenantId,
      undefined,
      false
    );
    if (chatSession) {
      firstVisit.value = chatSession.firstVisit;
    }
  }

  async function updateChatSession(chatSessionId: string, name: string) {
    if (!tenantStore.tenantId) {
      return;
    }

    await apiUpdateChatSession(chatSessionId, tenantStore.tenantId, name);
    const chatSession = chatSessions.value.find(
      (cs) => cs.id.toString() === chatSessionId.toString()
    );
    if (chatSession) {
      chatSession.name = name;
    }
  }

  return {
    chatSessions,
    shouldScrollToTop,
    skill,
    documentToReload,
    isUploading,
    fetchChatSessions,
    addChatSession,
    archiveChatSession,
    moveChatSessionToTop,
    updateChatSession,
    markChatSessionAsVisited,
    firstVisit,
    showChatUX,
    isLoadingChatSession,
  };
});
