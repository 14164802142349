<script setup lang="ts">
import { FilePlus2, Loader2, Paperclip } from "lucide-vue-next";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useTenantStore } from "@/stores/tenant";
import { useQuickUploadDocuments } from "@/composables/useQuickUploadDocuments";
import DataSourceSelector from "@/components/DataSources/DataSourceSelector.vue";
import DataSourceSelectorMenuEntry from "@/components/ChatView/guided-agent-selection/DataSourceSelectorMenuEntry.vue";
import DataSourceCreate from "@/components/DataSources/DataSourceCreate.vue";
import DatasourceIconRounded from "@/components/DataSources/DatasourceIconRounded.vue";
import { DataSource } from "@/types";
import { useApiRequest } from "@/composables/useApiRequest";
import { getDataSources } from "@/services/data_source";

type Props = {
  hideDataSourceCreation?: boolean;
};
const { hideDataSourceCreation } = withDefaults(defineProps<Props>(), {
  hideDataSourceCreation: false,
});

const tenantStore = useTenantStore();
const tenantId = ref<string | undefined>(tenantStore.tenantId);
const route = useRoute();
const sessionId = ref(route.params.session_id as string | undefined);

const reloadDataSources = ref<boolean>(false);
const isDataSourceCreateDialogOpen = ref(false);

const { loading, data, execute } = useApiRequest<DataSource[]>();

const fetchDataSources = async (tenantId: string | undefined) => {
  if (!tenantId) return;
  return await execute(() => getDataSources(tenantId));
};

const { fileInput, onFilesSelected, triggerFileInput, acceptExtensionValue } =
  useQuickUploadDocuments(tenantId, sessionId, (skill) => {
    console.log("Skill selected from DataSourceSelectorMenu:", skill);
  });

function onAttachDocumentsClicked() {
  triggerFileInput();
}

async function onFilesSelectedAndReload() {
  await onFilesSelected();
  reloadDataSources.value = true;
}

onMounted(() => {
  fetchDataSources(tenantId.value);
});
</script>

<template>
  <div class="data_source_selectors">
    <div class="h-full flex items-center" v-if="loading">
      <Loader2 class="w-5 h-5 animate-spin" />
    </div>
    <div v-else class="data_source_selector_menu flex flex-col gap-4 w-full">
      <DataSourceSelectorMenuEntry
        :label="'Attach documents to this chat'"
        :hint="'5 documents or less'"
        :icon="Paperclip"
        @click="onAttachDocumentsClicked"
      />
      <DataSourceSelector
        v-if="data"
        v-model="reloadDataSources"
        :icon="DatasourceIconRounded"
        :boxed="false"
      />
      <DataSourceSelectorMenuEntry
        :label="'Create a data source'"
        :hint="'More than 5 documents'"
        :icon="FilePlus2"
        @click="isDataSourceCreateDialogOpen = true"
        v-if="!hideDataSourceCreation"
      />

      <input
        type="file"
        :accept="acceptExtensionValue"
        ref="fileInput"
        class="hidden"
        multiple
        @change="onFilesSelectedAndReload"
      />

      <DataSourceCreate
        v-model:open="isDataSourceCreateDialogOpen"
        :showPlusButton="false"
      />
    </div>
  </div>
</template>
