import apiClient from "@/services/api_client";
import { ChatSession } from "@/types";

export async function createChatSession(name: string, tenantId: string) {
  const url = "/chatsessions";
  return await apiClient.post(url, {
    name: name,
    tenant_id: tenantId,
  });
}

export async function getChatSessions(
  tenantId: string
): Promise<ChatSession[]> {
  const url = "/chatsessions?tenant_id=" + tenantId;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function getChatSessionById(
  chatSessionId: string,
  tenantId: string
): Promise<ChatSession> {
  const url = `/chatsessions/${chatSessionId}?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function archiveChatSession(
  chatSessionId: string,
  tenantId: string
) {
  const url = "/chatsessions/archive";
  await apiClient.patch(url, {
    chat_session_id: chatSessionId,
    tenant_id: tenantId,
  });
}

export async function updateChatSession(
  chatSessionId: string,
  tenantId: string,
  name?: string,
  firstVisit?: boolean
) {
  const url = "/chatsessions/update";
  return await apiClient.post(url, {
    chat_session_id: chatSessionId,
    tenant_id: tenantId,
    name: name,
    first_visit: firstVisit,
  });
}

export async function addFileMessage(
  chatSessionId: string,
  tenantId: string,
  fileName: string
) {
  const url = "/chatsessions/add_file_message";
  return await apiClient.post(url, {
    chat_session_id: chatSessionId,
    tenant_id: tenantId,
    file_name: fileName,
  });
}
