<script setup lang="ts">
import { ref } from "vue";

const { multiple = true } = defineProps<{
  multiple?: boolean;
}>();

const isDropZoneActive = ref(false);
const dropZoneRef = ref<HTMLElement | null>(null);

const emit = defineEmits<{
  (e: "files-dropped", files: FileList): void;
}>();

const onDragEnter = (event: DragEvent) => {
  event.preventDefault();
  isDropZoneActive.value = true;
};

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
};

const onDragLeave = (event: DragEvent) => {
  event.preventDefault();

  if (dropZoneRef.value) {
    const rect = dropZoneRef.value.getBoundingClientRect();
    const x = event.clientX;
    const y = event.clientY;
    if (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) {
      isDropZoneActive.value = false;
    }
  }
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  isDropZoneActive.value = false;

  const files = event.dataTransfer?.files;
  if (files && files.length > 0) {
    if (!multiple && files.length > 1) {
      alert("Only one file is allowed");
      return;
    }
    emit("files-dropped", files);
  }
};
</script>
<template>
  <div
    ref="dropZoneRef"
    class="drop-zone w-full relative flex h-screen flex-1"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <div
      v-if="isDropZoneActive"
      class="absolute inset-0 z-50 bg-secondary/50 flex justify-center items-center text-secondary-foreground text-xl font-semibold"
    >
      Drop your files here
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>
.drop-zone {
  position: relative;
}
</style>
