<script setup lang="ts">
import TenantSelector from "./TenantSelector.vue";
import { RouteRecordName, RouterLink, useRoute } from "vue-router";
import { useTenantStore } from "@/stores/tenant";
import { useConfigCatStore } from "@/stores/configCat";
import ChatSessions from "./ChatSessions.vue";
import logo from "@/assets/logo.png";
import { ref } from "vue";
import UserPopOverMenu from "./sidebar/UserPopOverMenu.vue";

type CanAccessFunction = (tenantStore: any) => boolean;

const tenantStore = useTenantStore();
const configCatStore = useConfigCatStore();
const currentRoute = useRoute();
const isUserMenuOpen = ref(<boolean>false);

const canAccessRoute = (route: any) => {
  if (route.meta.showInSidebar === false) {
    return false;
  }
  if (route.path === "/catalog-management") {
    return configCatStore.catalogManagementTab && tenantStore.tenantAdmin;
  }
  return (route.meta.canAccess as CanAccessFunction)(tenantStore);
};

const assignClass = (routeName: RouteRecordName | undefined) => {
  if (routeName === "Data sources") {
    return "data_sources_button";
  }
  if (routeName === "Saved prompts") {
    return "saved_prompts_button";
  }
  return undefined;
};

const isRouteSelected = (routeName: RouteRecordName | undefined) => {
  if (!routeName || !currentRoute.name) return false;
  const prefix = `/${currentRoute.path.split("/")[1]}`;
  return prefix == routeName;
};
</script>

<template>
  <div class="sidebar w-64 flex flex-col justify-between h-screen">
    <div
      class="text-primary50 bg-foreground h-full overflow-y-auto flex-1 flex gap-3 w-full flex-col z-40 px-4 pt-8 shrink-0"
    >
      <div class="logo mb-3">
        <RouterLink to="/" class="flex items-center gap-3">
          <img alt="Playground" :src="logo" class="h-8 w-8" />
          <span
            class="text-background text-2xl font-spartan -leading-2 font-semibold"
            >Playground</span
          >
        </RouterLink>
      </div>
      <TenantSelector />
      <nav
        aria-label="sidebar"
        class="navbar flex flex-col justify-between w-full min-h-0 flex-1"
      >
        <div class="menu_top flex flex-col gap-5 w-full overflow-hidden">
          <div class="menu_items font-spartan font-normal text-lg shrink-0">
            <RouterLink
              v-for="route in $router.getRoutes()"
              :to="route.path"
              :key="route.name"
              class="group"
            >
              <div
                v-if="canAccessRoute(route)"
                class="flex items-center gap-3 rounded-full px-3 py-2 transition-all"
                :id="assignClass(route.path)"
              >
                <component
                  :is="route.meta.icon"
                  class="h-5 w-5"
                  :class="{ 'text-background': isRouteSelected(route.path) }"
                />
                <p
                  class="group-hover:underline"
                  :class="{ 'text-background': isRouteSelected(route.path) }"
                >
                  {{ route.name }}
                </p>
              </div>
            </RouterLink>
          </div>
          <ChatSessions />
        </div>
        <div class="menu_bottom shrink-0"></div>
      </nav>
    </div>
    <div
      class="sidebar_footer bg-foreground px-4 flex gap-0 items-center justify-between py-2"
    >
      <UserPopOverMenu v-model="isUserMenuOpen" />
    </div>
  </div>
</template>
