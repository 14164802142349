<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTenantStore } from "@/stores/tenant";
import { Plus, Eye, EyeOff, Lightbulb, Loader2 } from "lucide-vue-next";
import { computed, ref, watch } from "vue";
import { createDataSource } from "@/services/data_source";
import { Visibility } from "@/types";
import { useRouter } from "vue-router";

type Props = {
  open?: boolean;
  showPlusButton?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(["update:open"]);
const isDialogOpen = ref<boolean>(props.open ?? false);
const router = useRouter();

watch(
  () => props.open,
  (newVal) => {
    if (typeof newVal === "boolean") {
      isDialogOpen.value = newVal;
    }
  }
);

watch(isDialogOpen, (newVal) => {
  emit("update:open", newVal);
});

const tenantStore = useTenantStore();

const newDataSourceName = ref<string>("");
const newDataSourceRagType = ref("MULTI_MODAL");
const newDataSourceDescription = ref<string>("");
const errorMessage = ref<string | null>(null);
const isLoading = ref(false);
const isInputTouched = ref(false);
const isAdmin = tenantStore.tenantAdmin;
const selectedVisibility = ref<Visibility>(Visibility.PRIVATE);
const visibilityValues = Object.values(Visibility);

const forbiddenCharacters = ["'"];
const noForbiddenCharacters = ref<boolean>(true);

async function handleCreateDataSource() {
  isLoading.value = true;
  if (
    isCreateButtonDisabled.value ||
    !tenantStore.tenantId ||
    !noForbiddenCharacters.value
  ) {
    isLoading.value = false;
    return;
  }
  try {
    const response = await createDataSource(
      newDataSourceName.value,
      tenantStore.tenantId,
      selectedVisibility.value,
      newDataSourceDescription.value
    );

    router.push({ name: "DataSource", params: { id: response.id } });

    newDataSourceName.value = "";
    isInputTouched.value = false;
    newDataSourceDescription.value = "";
    selectedVisibility.value = Visibility.PRIVATE;

    isDialogOpen.value = false;
  } catch (error: any) {
    if (error instanceof Error) {
      errorMessage.value = error.message;
    } else {
      errorMessage.value = "An unexpected error occurred.";
    }
  } finally {
    isLoading.value = false;
  }
}

const isCreateButtonDisabled = computed(() => {
  return (
    !newDataSourceName.value ||
    !newDataSourceRagType.value ||
    !noForbiddenCharacters.value
  );
});

const handleInputTouched = () => {
  isInputTouched.value = true;
};

watch(newDataSourceName, (newName) => {
  if (!isInputTouched.value) {
    return;
  }
  if (!newName) {
    errorMessage.value = "Data source name cannot be empty.";
    noForbiddenCharacters.value = true;
    return;
  }

  const containsForbidden = forbiddenCharacters.some((char) =>
    newName.includes(char)
  );

  if (containsForbidden) {
    errorMessage.value = `Name contains forbidden characters: ${forbiddenCharacters.join(", ")}`;
    noForbiddenCharacters.value = false;
  } else {
    errorMessage.value = null;
    noForbiddenCharacters.value = true;
  }
});
</script>

<template>
  <Dialog v-model:open="isDialogOpen" class="custom-alert-dialog">
    <DialogTrigger asChild v-if="props.showPlusButton !== false">
      <div
        class="datasource_card_create w-full min-h-48 flex items-center justify-center gap-4 rounded-lg bg-primary20 border border-primary30 cursor-pointer transition-colors duration-200 ease-in-out hover:bg-primary30"
      >
        <div class="p-4 w-full text-center">
          <Plus class="w-6 h-6 text-foreground mx-auto" />
        </div>
      </div>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create New Data Source</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <form @submit.prevent="handleCreateDataSource">
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              @input="handleInputTouched"
              v-model="newDataSourceName"
              class="alertDialog_name_input mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
              autocomplete="off"
            />
            <p v-if="errorMessage" class="text-red-500 text-sm">
              {{ errorMessage }}
            </p>
          </div>
          <div>
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
              >Description (Optional)</label
            >
            <textarea
              id="description"
              v-model="newDataSourceDescription"
              class="description_textarea mt-1 p-2 border border-gray-300 rounded-md w-full"
              rows="10"
              maxlength="2000"
              placeholder="Enter a description for the data source (optional)"
            />
          </div>
          <div v-if="isAdmin" class="mt-4">
            <Select v-model="selectedVisibility">
              <SelectTrigger class="w-auto">
                <SelectValue class="!flex !items-center gap-2 p-2">
                  <Eye
                    v-if="selectedVisibility === Visibility.PUBLIC"
                    class="text-primary"
                  />
                  <EyeOff v-else class="text-primary" />
                  <p class="text-black">
                    {{
                      selectedVisibility.charAt(0).toUpperCase() +
                      selectedVisibility.slice(1)
                    }}
                  </p>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem
                  v-for="value in visibilityValues"
                  :key="value"
                  :value="value"
                >
                  {{ value.charAt(0).toUpperCase() + value.slice(1) }}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div class="flex !items-center gap-1 p-3">
            <Lightbulb class="w-4 h-4 text-yellow-500 flex-none" />
            <p
              v-if="selectedVisibility === Visibility.PRIVATE"
              class="text-xs text-gray-500"
            >
              Only you will be able to see, edit, and question this data source.
            </p>
            <p
              v-else-if="selectedVisibility === Visibility.PUBLIC"
              class="text-xs text-gray-500"
            >
              All your organization members will be able to see and question
              this data source. Only you will be able to edit and see its
              content.
            </p>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              type="button"
              @click="isDialogOpen = false"
              >Cancel
            </Button>
            <Button
              v-if="!isLoading"
              type="submit"
              class="alertDialog_create_button"
              :disabled="isCreateButtonDisabled"
              >Create</Button
            >
            <Button v-else disabled>
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
              Create
            </Button>
          </DialogFooter>
        </form>
      </DialogDescription>
    </DialogContent>
  </Dialog>
</template>
