<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Loader2, MoveLeft } from "lucide-vue-next";
import OptionSelector from "@/components/ChatView/guided-agent-selection/OptionSelector.vue";
import DataSourceSelectorMenu from "@/components/ChatView/guided-agent-selection/DataSourceSelectorMenu.vue";
import TemplatePicker from "../flow/TemplatePicker.vue";
import Button from "@/components/ui/button/Button.vue";
import { Textarea } from "@/components/ui/textarea";
import { useTenantStore } from "@/stores/tenant";
import { FilePlus2 } from "lucide-vue-next";
import { DataSource, Skills } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { documentGenerator } from "@/services/websocket";
import { selectDataSources } from "@/services/data_source";
import { useChatSessionsStore } from "@/stores/chatSessions";
import documentTypePlaceholders from "./documentGeneratorPlaceholders";
import { Progress } from "@/components/ui/progress";

type DocumentType = keyof typeof documentTypePlaceholders;

const route = useRoute();
const tenantId = ref<string | undefined>(
  useTenantStore().tenantId ?? undefined
);
const chatSessionId = ref<string | undefined>(
  route.params.session_id as string
);
const chatSessionsStore = useChatSessionsStore();
const toolbarStore = useToolbarStore();
const authStore = useAuthStore();

const templateDataSourceId = ref<number | undefined>(undefined);
const userMessageInput = ref<string | undefined>(undefined);
const selectedDataSources = ref<DataSource[]>([]);
const selectedTemplateName = ref<string | undefined>(undefined);
const selectedDocumentType = ref<DocumentType>("Commercial Proposal");
const loadingDocumentGeneration = ref(false);
const emit = defineEmits<{
  (e: "setSelectedSkill", skill: Skills): void;
}>();

const parameters = computed(() => {
  return {
    userMessageInput: userMessageInput.value,
    contextDataSources: selectedDataSources.value,
    templateDataSourceId: templateDataSourceId.value,
  };
});

const disableButton = computed(() => {
  return (
    !parameters.value.userMessageInput ||
    !parameters.value.templateDataSourceId ||
    !selectedDataSources.value.length
  );
});

const handleTemplateDataSourceId = (
  dataSourceId: number,
  templateName: string
) => {
  templateDataSourceId.value = dataSourceId;
  selectedTemplateName.value = templateName;
};

const handleGenerateDocument = async () => {
  const token = await authStore.aquireToken();
  const dataSourceIds = selectedDataSources.value.map(
    (dataSource) => dataSource.id
  );

  if (
    !userMessageInput.value ||
    !tenantId.value ||
    !chatSessionId.value ||
    !dataSourceIds ||
    !templateDataSourceId.value
  ) {
    return;
  }
  try {
    loadingDocumentGeneration.value = true;
    documentGenerator(
      userMessageInput.value,
      tenantId.value,
      chatSessionId.value,
      dataSourceIds,
      templateDataSourceId.value,
      token,
      authStore.isInsideTeams,
      selectedDocumentType.value
    );
    await chatSessionsStore.markChatSessionAsVisited(chatSessionId.value);
    toolbarStore.setSelectedSkill(Skills.CHAT_WITH_DOCUMENTS);
    loadingDocumentGeneration.value = false;
  } catch (e) {
    console.error(e);
  }
};

const handleBackClick = async () => {
  if (chatSessionId.value && tenantId.value) {
    await selectDataSources(chatSessionId.value, [], [], tenantId.value);
  }
  toolbarStore.setSelectedDatasources([]);
  emit("setSelectedSkill", Skills.NONE);
};

watch(
  () => toolbarStore.selectedDataSources,
  (userDataSources) => {
    if (userDataSources) {
      selectedDataSources.value = userDataSources;
    }
  },
  { deep: true }
);

const currentPlaceholder = computed(() => {
  return (
    documentTypePlaceholders[selectedDocumentType.value] ||
    documentTypePlaceholders["Commercial Proposal"]
  );
});

const handleDocumentTypeSelection = (type: string) => {
  if (type === selectedDocumentType.value) return;
  selectedDocumentType.value = type as DocumentType;
  // userMessageInput.value = ""; // Reset l'input lors du changement de type
};

const currentStep = ref(0);
const steps = [
  { title: "Document Type", description: "Select your document type" },
  { title: "Project Details", description: "Describe your project and goals" },
  { title: "Source Documents", description: "Select source documents" },
  { title: "Template", description: "Select a Structural Example Document" },
];

const progress = computed(() => {
  return ((currentStep.value + 1) / steps.length) * 100;
});

const canGoNext = computed(() => {
  switch (currentStep.value) {
    case 0:
      return selectedDocumentType.value !== undefined;
    case 1:
      return userMessageInput.value && userMessageInput.value.length > 0;
    case 2:
      return selectedDataSources.value.length > 0;
    case 3:
      return templateDataSourceId.value !== undefined;
    default:
      return false;
  }
});

const nextStep = () => {
  if (currentStep.value < steps.length - 1) {
    currentStep.value++;
  }
};

const previousStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};
</script>

<template>
  <div
    class="document_generator_setup_page flex flex-col w-full h-full py-10 px-8 gap-12 overflow-y-auto"
  >
    <div
      class="flex items-center gap-2 cursor-pointer group text-muted-foreground hover:text-foreground"
      @click="handleBackClick"
    >
      <MoveLeft class="w-4 h-4" />
      <p class="text-sm group-hover:underline">Back to agent selection</p>
    </div>

    <div
      class="max-w-[600px] w-full flex-grow m-auto flex flex-col gap-12 justify-center"
    >
      <div class="header">
        <h1 class="w-full text-center text-4xl font-spartan mb-12">
          Document content generator
        </h1>
        <div>
          <Progress :modelValue="progress" class="w-full h-1" />
          <div class="flex justify-between mt-5">
            <span class="text-sm text-muted-foreground">
              Step {{ currentStep + 1 }} of {{ steps.length }}
            </span>
            <span class="text-sm font-medium">{{
              steps[currentStep].title
            }}</span>
          </div>
        </div>
      </div>

      <div>
        <!-- Step 1: Document Type -->
        <div v-if="currentStep === 0">
          <h2 class="text-lg font-medium mb-6">{{ steps[0].description }}</h2>
          <div class="grid grid-cols-2 gap-4 w-full">
            <OptionSelector
              v-for="(_, type) in documentTypePlaceholders"
              :key="type"
              :label="type"
              :icon="FilePlus2"
              :selected="selectedDocumentType === type"
              @click="handleDocumentTypeSelection(type)"
            />
          </div>
        </div>

        <!-- Step 2: Project Details -->
        <div v-if="currentStep === 1">
          <h2 class="text-lg font-medium mb-4">{{ steps[1].description }}</h2>
          <p class="text-sm text-muted-foreground mb-6">
            Provide all the details about your project and objectives.
          </p>
          <Textarea
            id="user-input-text"
            class="w-full min-h-60 flex-1 text-sm font-light"
            v-model="userMessageInput"
            :placeholder="currentPlaceholder"
          />
        </div>

        <!-- Step 3: Source Documents -->
        <div v-if="currentStep === 2">
          <h2 class="text-lg font-medium mb-4">{{ steps[2].description }}</h2>
          <p class="text-sm text-muted-foreground mb-6">
            These documents will act as a knowledge base to provide content for
            your output.
          </p>
          <DataSourceSelectorMenu :hideDataSourceCreation="true" />
        </div>

        <!-- Step 4: Template Selection -->
        <div v-if="currentStep === 3">
          <h2 class="text-lg font-medium mb-4">{{ steps[3].description }}</h2>
          <p class="text-sm text-muted-foreground mb-6">
            This document will help to define the
            <span class="font-semibold">structure</span>,
            <span class="font-semibold">tone</span>, and
            <span class="font-semibold">length</span> of your output.
          </p>
          <TemplatePicker
            :tenantId="tenantId"
            :isDropZone="false"
            @onSelection="handleTemplateDataSourceId"
          />
        </div>
      </div>

      <div class="flex justify-between">
        <Button
          variant="outline"
          @click="previousStep"
          :disabled="currentStep === 0"
        >
          Previous
        </Button>
        <Button
          v-if="currentStep < steps.length - 1"
          @click="nextStep"
          :disabled="!canGoNext"
        >
          Next
        </Button>
        <Button
          v-else
          class="flex items-center gap-2"
          @click="handleGenerateDocument"
          :disabled="disableButton || loadingDocumentGeneration"
        >
          <Loader2
            v-if="loadingDocumentGeneration"
            class="animate-spin w-4 h-4"
          />
          Generate document
        </Button>
      </div>
    </div>
  </div>
</template>
