import { useToast } from "@/components/ui/toast/use-toast";
import apiClient from "@/services/api_client";
import { ref } from "vue";
import { addFileMessage } from "./chat_sessions";
import { messages } from "./websocket";

export const dictDocumentsLoadingProgress = ref<{ [key: string]: number }>({});

const toast = useToast();

export const allowedExtensions = ["pdf", "docx", "doc", "pptx", "xlsx"];
export const acceptExtensionValue = allowedExtensions
  .map((ext) => `.${ext}`)
  .join(",");

export async function processFiles(
  files: File[],
  tenantId: string,
  dataSourceId?: string,
  chatSessionId?: string | undefined
): Promise<void> {
  const validFiles = files.filter((file) => {
    const extension = file.name.split(".").pop()?.toLowerCase();
    return extension && allowedExtensions.includes(extension);
  });

  if (validFiles.length === 0) {
    toast.toast({
      title: "Invalid Files",
      description: "No valid files selected.",
      variant: "destructive",
      duration: 3000,
    });
    return;
  }

  const totalFiles = validFiles.length;
  const successfulFiles: string[] = [];

  const sharedToast = toast.toast({
    title: `Uploading document 1/${totalFiles}`,
    description: `Starting upload...`,
    duration: Infinity,
  });

  for (let index = 0; index < validFiles.length; index++) {
    const file = validFiles[index];
    const timestamp = new Date().toISOString();
    dictDocumentsLoadingProgress.value[timestamp] = 1;
    if (chatSessionId) {
      messages.value.push({
        type: "file",
        content: timestamp,
        fileName: file.name,
        isLoading: false,
      });
      dictDocumentsLoadingProgress.value[timestamp] = 1;
    }

    const onProgress = (fileProgress: number) => {
      const globalProgress = ((index + fileProgress / 100) / totalFiles) * 100;
      dictDocumentsLoadingProgress.value[timestamp] = fileProgress - 10;
      sharedToast.update({
        id: sharedToast.id,
        title: `Uploading document ${index + 1}/${totalFiles}`,
        description: `Global Progress: ${Math.floor(globalProgress)}%`,
      });
    };

    try {
      await uploadFile(
        file,
        tenantId,
        totalFiles,
        index + 1,
        dataSourceId,
        chatSessionId,
        sharedToast,
        onProgress
      );
      successfulFiles.push(file.name);
      dictDocumentsLoadingProgress.value[timestamp] = 100;
      if (chatSessionId) {
        addFileMessage(chatSessionId, tenantId, file.name);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  sharedToast.update({
    id: sharedToast.id,
    title: "Upload Complete",
    description: `All files uploaded successfully.`,
    duration: 2000,
  });

  if (successfulFiles.length > 0) {
    try {
      await indexFiles(
        successfulFiles,
        Number(dataSourceId),
        tenantId,
        chatSessionId
      );
    } catch (error) {
      console.error("Error indexing files:", error);
    }
  }
}

async function indexFiles(
  filesNames: string[],
  data_source_id: number,
  tenantId: string,
  chatSessionId?: string
): Promise<void> {
  const url = "/index";
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    data_source_id: data_source_id,
    files_names: filesNames,
    chat_session_id: chatSessionId,
  });
  return await response;
}

export async function uploadFile(
  file: File,
  tenantId: string,
  totalFiles: number = 1,
  fileIndex: number = 1,
  dataSourceId?: string,
  chatSessionId?: string,
  sharedToast?: { id: string; update: (options: any) => void },
  onProgress?: (progress: number) => void
): Promise<string | null> {
  const chunkSize = 1 * 1024 * 1024; // 1MB
  const totalChunks = Math.ceil(file.size / chunkSize);
  let offset = 0;
  let chunkNumber = 0;
  let filePath = undefined;

  const toastInstance =
    sharedToast ||
    toast.toast({
      title: `Uploading document ${fileIndex}/${totalFiles}`,
      description: `${file.name} - Progress: 0%`,
      duration: Infinity,
    });

  const updateProgress = (progress: number) => {
    if (onProgress) {
      onProgress(progress);
    } else if (!sharedToast) {
      toastInstance.update({
        id: toastInstance.id,
        title: `Uploading document ${fileIndex}/${totalFiles}`,
        description: `${file.name} - Progress: ${progress}%`,
      });
    }
  };

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunkSize);
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("name", file.name);
    formData.append("chunk_number", String(chunkNumber));
    formData.append("total_chunks", String(totalChunks));
    formData.append("tenant_id", tenantId);
    formData.append("data_source_id", dataSourceId ?? "-1");
    formData.append("file_path", filePath);
    formData.append("chat_session_id", chatSessionId ?? "");

    try {
      const response = await apiClient.postFormData("/uploads", formData);
      filePath = response.file_path;
    } catch (error) {
      toastInstance.update({
        id: toastInstance.id,
        title: "Upload Error",
        description: `Failed to upload ${file.name}.`,
        variant: "destructive",
        duration: 2000,
      });
      return null;
    }

    offset += chunkSize;
    chunkNumber++;
    const progress = Math.floor((offset / file.size) * 100);
    updateProgress(Math.min(progress, 100));

    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  if (!sharedToast) {
    toastInstance.update({
      id: toastInstance.id,
      title: "Upload Complete",
      description: `${file.name} uploaded successfully.`,
      duration: 2000,
    });
  }

  return filePath;
}

export async function updateDataSourceItem(
  dataSourceItemId: string,
  active: Boolean,
  tenantId: string
) {
  const url = "/datasource_item";
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    data_source_item_id: dataSourceItemId,
    active: active,
  });
  return await response;
}
