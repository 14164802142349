<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useTenantStore } from "@/stores/tenant";
import { deleteSavedPrompt } from "@/services/saved_prompts";
import { SavedPrompt } from "@/types";

const tenantStore = useTenantStore();

const props = defineProps<{
  savedPrompt: SavedPrompt;
  loadSavedPrompts: () => void;
}>();

const isDeleteDialogOpen = defineModel<boolean>("isDeleteDialogOpen");

const handleDeletePrompt = async () => {
  if (!tenantStore.tenantId) {
    return;
  }
  isDeleteDialogOpen.value = false;
  await deleteSavedPrompt(props.savedPrompt.id, tenantStore.tenantId);
  await props.loadSavedPrompts();
};
</script>

<template>
  <Dialog v-model:open="isDeleteDialogOpen">
    <DialogContent
      class="flex flex-col items-center text-center w-fit p-8 !max-w-full"
    >
      <DialogHeader>
        <DialogTitle>Delete prompt</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Are you sure you want to delete this prompt?
      </DialogDescription>
      <div class="flex gap-4 items-center">
        <Button variant="destructive" @click="handleDeletePrompt()"
          >Delete</Button
        >
        <Button variant="secondary" @click="isDeleteDialogOpen = false"
          >Cancel</Button
        >
      </div>
    </DialogContent>
  </Dialog>
</template>
