<script setup lang="ts">
import { ref, onMounted } from "vue";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-vue-next";
import Input from "./ui/input/Input.vue";
import { createTag, getTags, deleteTag, updateTag } from "@/services/tags";
import { Tag } from "@/types";

const isTagDialogOpen = defineModel<boolean>("isTagDialogOpen");
const tags = defineModel<Tag[]>("tags");

const newTag = ref("");
const tenantId = "__global__";

const editingTagId = ref<number | null>(null);
const editedTagName = ref("");

function startEditing(tag: Tag) {
  editingTagId.value = tag.id;
  editedTagName.value = tag.name;
}

async function handleEdit(tagId: number) {
  if (editedTagName.value.trim()) {
    await updateTag(tagId, editedTagName.value, tenantId);
    editingTagId.value = null;
    editedTagName.value = "";
    tags.value = await getTags(tenantId);
  }
}

function cancelEdit() {
  editingTagId.value = null;
  editedTagName.value = "";
}

async function handleCreate(tag: string) {
  if (tag.trim()) {
    await createTag(tag, tenantId);
    newTag.value = "";
    tags.value = await getTags(tenantId);
  }
}

async function handleDelete(tagId: number) {
  await deleteTag(tagId, tenantId);
  tags.value = await getTags(tenantId);
}

async function fetchTags() {
  tags.value = await getTags(tenantId);
}

onMounted(fetchTags);
</script>

<template>
  <Dialog v-model:open="isTagDialogOpen">
    <DialogTrigger asChild>
      <Button variant="ghost" class="p-2">
        <Plus class="w-5 h-5 text-muted-foreground" />
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Edit categories</DialogTitle>
      </DialogHeader>
      <div class="flex items-center gap-2">
        <Input
          v-model="newTag"
          type="text"
          placeholder="Enter category name"
          class="border rounded px-2 py-1"
          @keydown.enter="handleCreate(newTag)"
        />
        <Button @click="handleCreate(newTag)" variant="ghost"> Create </Button>
      </div>
      <div class="h-80 overflow-auto">
        <ul class="mt-4 space-y-1">
          <li v-for="tag in tags" :key="tag.id" class="flex items-center gap-2">
            <Button
              variant="ghost"
              @click="handleDelete(tag.id)"
              class="rounded-full w-9 h-9"
            >
              <X class="w-5 h-5 shrink-0"></X>
            </Button>
            <span
              v-if="editingTagId !== tag.id"
              @click="startEditing(tag)"
              class="cursor-pointer"
            >
              {{ tag.name }}
            </span>
            <div v-else class="flex items-center gap-2">
              <Input
                v-model="editedTagName"
                @keydown.enter="handleEdit(tag.id)"
              />
              <Button variant="ghost" @click="handleEdit(tag.id)">Save</Button>
              <Button variant="ghost" @click="cancelEdit">Cancel</Button>
            </div>
          </li>
        </ul>
      </div>
    </DialogContent>
  </Dialog>
</template>
