<script setup lang="ts">
import { dictDocumentsLoadingProgress } from "@/services/upload_document";
import { FileText } from "lucide-vue-next";
import { computed } from "vue";

type Props = {
  fileName: string;
  content: string;
};

const { fileName, content } = defineProps<Props>();

const getExtension = (filename: string) => {
  const parts = filename.split(".");
  return parts[parts.length - 1].toUpperCase();
};

const backgroundStyle = computed(() => {
  const message = content || "";

  const progress = dictDocumentsLoadingProgress.value[message] || 100;

  return {
    background: `linear-gradient(to right, hsl(var(--primary-shade-20)) ${progress}%, #ffffff ${progress}%)`,
  };
});
</script>

<template>
  <div
    class="flex items-center p-2 border border-gray-100 bg-primary20 rounded-md w-72 relative overflow-hidden"
    :style="backgroundStyle"
  >
    <div
      class="flex items-center justify-center bg-foreground90 rounded-md w-7 h-7 shrink-0 mr-2"
    >
      <FileText class="text-white w-4 h-4" />
    </div>

    <div class="leading-tight flex-1">
      <div class="font-medium text-gray-700 text-sm w-4/5 truncate">
        {{ fileName }}
      </div>
      <div class="text-xs text-gray-500">
        {{ fileName ? getExtension(fileName) : "" }}
      </div>
    </div>
  </div>
</template>
