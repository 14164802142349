<script setup lang="ts">
import { ref, watchEffect } from "vue";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Loader2 } from "lucide-vue-next";
import { useTenantStore } from "@/stores/tenant";
import { DataSource } from "@/types";
import { getDataSources } from "@/services/data_source";
import DataSourceCard from "@/components/DataSources/DataSourceCard.vue";
import { useRouter } from "vue-router";
import DataSourceCreate from "@/components/DataSources/DataSourceCreate.vue";
import { useConfigCatStore } from "@/stores/configCat";
import TourDatasources from "@/components/TourDatasources.vue";
import { fetchOnboardingTourStatus } from "@/services/onboarding_tour";
import { useApiRequest } from "@/composables/useApiRequest";

const tenantStore = useTenantStore();

const isAdmin = tenantStore.tenantAdmin;
const configCatStore = useConfigCatStore();
const router = useRouter();

const isDialogOpen = ref<boolean>(false);
const readyForTour = ref<boolean>(false);

const {
  loading: loadingDataSources,
  data: dataSources,
  execute: fetchDataSources,
} = useApiRequest<DataSource[]>();

const navigateToDataSource = (id: number) => {
  router.push({ name: "DataSource", params: { id } });
};

watchEffect(async () => {
  const tenantId = tenantStore.tenantId;
  if (tenantId) {
    await fetchDataSources(() => getDataSources(tenantId));
    await fetchOnboardingTourStatus("datasource_creation", tenantId);
    readyForTour.value = true;
  }
});
</script>

<template>
  <TourDatasources
    v-if="
      readyForTour &&
      configCatStore.onboarding &&
      (!configCatStore.restrictDataSourceCreateToAdmins || isAdmin)
    "
    v-model:isDialogOpen="isDialogOpen"
  />
  <div class="h-screen flex flex-col overflow-y-scroll gap-4 p-8">
    <div class="header">
      <Breadcrumb class="mb-2">
        <BreadcrumbList class="p-0 list-none">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Datasources</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <h1 class="font-spartan font-semibold text-2xl">Data Sources</h1>
    </div>
    <div>
      <Loader2 v-if="loadingDataSources" class="animate-spin h-5 w-5 mt-4" />
      <div
        v-else
        class="datasources_list_container grid grid-flow-row grid-cols-[repeat(auto-fit,_minmax(300px,1fr))] auto-rows-auto gap-4"
      >
        <div id="add_source_button">
          <DataSourceCreate
            v-if="!configCatStore.restrictDataSourceCreateToAdmins || isAdmin"
            :showPlusButton="true"
          />
        </div>
        <DataSourceCard
          v-for="dataSource in dataSources"
          :key="dataSource.id"
          :dataSource="dataSource"
          @click="navigateToDataSource(dataSource.id)"
        />
      </div>
    </div>
  </div>
</template>
