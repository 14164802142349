import { useAuthStore } from "@/stores/auth";
import { defineStore } from "pinia";
import { ref } from "vue";

export type UserTenant = {
  tenantId: string;
  roleNames?: string[];
  tenantName: string;
};

export const useTenantStore = defineStore("tenant", () => {
  const tenantId = ref<string>();
  const tenantName = ref<string>();
  const tenantRoles = ref<string[]>();
  const tenantAdmin = ref<boolean>(false);
  const tenantElqano = ref<boolean>(false);

  const authStore = useAuthStore();

  function updateTenant(tenant: any) {
    if (tenantId.value != tenant.tenantId) {
      authStore.updateCredentials(tenant.tenantId);
    }

    const roles = tenant.roleNames ?? [];
    const isAdmin = roles ? roles.includes("Tenant Admin") : false;
    const isElqano = tenant.tenantName === "Elqano";
    tenantId.value = tenant.tenantId;
    tenantName.value = tenant.tenantName;
    tenantRoles.value = roles;
    tenantAdmin.value = isAdmin;
    tenantElqano.value = isElqano;
  }

  return {
    tenantId,
    tenantName,
    tenantRoles,
    tenantAdmin,
    tenantElqano,
    updateTenant,
  };
});
