import apiClient from "@/services/api_client";
import { Item } from "@/types";

export async function storeTemplate(
  tenantId: string,
  dataSourceId: number,
  filePath: string,
  fileName: string
): Promise<Item> {
  const url = `/datasources/${dataSourceId}/items`;
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    file_path: filePath,
    file_name: fileName,
  });
  return await response;
}

export async function selectTemplate(
  tenantId: string,
  dataSourceId: number,
  datasourceItemId: string
): Promise<Item> {
  const url = `/datasources/${dataSourceId}/items/${datasourceItemId}/activate`;
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
  });
  return await response;
}
