<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { updateSavedPrompt } from "@/services/saved_prompts";
import { getSkills } from "@/services/skill";
import { getTags } from "@/services/tags";
import { useTenantStore } from "@/stores/tenant";
import { SavedPrompt, Skill, Skills, Tag, Visibility } from "@/types";
import {
  ArrowUpFromLine,
  Brain,
  Check,
  ChevronsUpDown,
  Loader2,
  NotepadTextDashed,
} from "lucide-vue-next";
import { computed, onMounted, ref, watch } from "vue";
import TagEdit from "./TagEdit.vue";

const isPopoverOpen = ref(false);
const searchValue = ref("");

type Props = {
  savedPrompt: SavedPrompt | null;
  loadSavedPrompts: Function;
  isEditingCatalog: boolean;
};

const { savedPrompt, loadSavedPrompts, isEditingCatalog } =
  defineProps<Props>();

const tenantStore = useTenantStore();
const selectedTenant = computed<string>(() =>
  isEditingCatalog ? "__global__" : tenantStore.tenantId || ""
);
const newName = ref<string | undefined>(savedPrompt?.name);
const newContent = ref<string | undefined>(savedPrompt?.content);
const newDescription = ref<string | undefined>(savedPrompt?.description);
const isUpdating = ref(false);
const selectedVisibility = ref<string | undefined>(savedPrompt?.visibility);
const visibilityValues = Object.values(Visibility);
const skills = ref<Skill[]>([]);
const selectedSkill = ref<string | undefined>(
  savedPrompt?.skill_name || Skills.NONE
);
const isTagDialogOpen = ref(false);
const selectedTag = ref<Tag | undefined>(
  savedPrompt?.tags ? savedPrompt.tags[0] : undefined
);
const tags = ref<Tag[]>([]);
const noAgentSelectedName = "Manually selected agent";

const selectedSkillDescription = computed(() => {
  const skill = skills.value.find(
    (skill) => skill.name === selectedSkill.value
  );
  return skill?.description;
});

const allowSave = computed(() => {
  return (
    (savedPrompt?.name &&
      newName.value !== "" &&
      savedPrompt?.name !== newName.value) ||
    (savedPrompt?.content &&
      newContent.value !== "" &&
      savedPrompt?.content !== newContent.value) ||
    (savedPrompt?.visibility &&
      savedPrompt?.visibility !== selectedVisibility.value) ||
    (savedPrompt?.skill_name &&
      savedPrompt?.skill_name !== selectedSkill.value) ||
    (!savedPrompt?.skill_name && selectedSkill.value !== Skills.NONE) ||
    savedPrompt?.description !== newDescription.value ||
    savedPrompt?.tags?.[0]?.id !== selectedTag.value?.id
  );
});

const isEditDialogOpen = defineModel<boolean>("isEditDialogOpen");

async function updatePrompt() {
  if (!savedPrompt || !newName.value || !newContent.value) {
    return;
  }
  isUpdating.value = true;
  await updateSavedPrompt(
    savedPrompt.id,
    selectedTenant.value,
    newName.value,
    newContent.value,
    newDescription.value,
    selectedVisibility.value,
    selectedSkill.value,
    selectedTag.value?.id
  );
  await loadSavedPrompts();
  isEditDialogOpen.value = false;
  isUpdating.value = false;
}

onMounted(async () => {
  if (isEditingCatalog) {
    tags.value = await getTags(selectedTenant.value);
  }
  if (!tenantStore.tenantId) {
    return;
  }
  const response = await getSkills(tenantStore.tenantId);
  skills.value = response.skills;
  skills.value.push({
    name: noAgentSelectedName,
    description: "No agent automatically selected",
  });
});

watch(
  () => savedPrompt,
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      newName.value = newVal.name;
      newContent.value = newVal.content;
      newDescription.value = newVal.description;
      selectedVisibility.value = newVal.visibility;
      selectedSkill.value = newVal.skill_name || Skills.NONE;
      selectedTag.value = newVal.tags ? newVal.tags[0] : undefined;
    }
  }
);
</script>

<template>
  <Dialog v-model:open="isEditDialogOpen">
    <DialogContent class="h-4/5 flex flex-col overflow-y-auto">
      <DialogHeader>
        <DialogTitle>Edit use case</DialogTitle>
      </DialogHeader>
      <div class="gap-2">
        <h1 class="mb-4 text-sm font-bold">Agent</h1>
        <div>
          <Select v-model="selectedSkill">
            <SelectTrigger class="w-auto ml-2">
              <SelectValue class="!flex !items-center gap-2 p-2">
                <Brain class="text-primary shrink-0 w-5 h-5" />
                <p class="text-black">
                  {{ selectedSkill }}
                </p>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                v-for="skill in skills"
                :key="skill.name"
                :value="skill.name"
              >
                <p
                  :class="[
                    'flex items-center gap-1',
                    skill.name === noAgentSelectedName
                      ? 'text-muted-foreground'
                      : '',
                  ]"
                >
                  {{ skill.name }}
                </p>
              </SelectItem>
            </SelectContent>
          </Select>
          <p class="ml-2 mt-1 text-sm text-muted-foreground">
            {{ selectedSkillDescription }}
          </p>
        </div>
        <div v-if="isEditingCatalog">
          <h1 class="mb-4 mt-2 text-sm font-bold">Category</h1>
          <div class="flex items-center gap-2">
            <Popover v-model:open="isPopoverOpen">
              <PopoverTrigger as-child>
                <Button
                  variant="outline"
                  role="combobox"
                  :aria-expanded="isPopoverOpen"
                  class="justify-between"
                >
                  <p class="text-black">
                    {{ selectedTag?.name || "Select category" }}
                  </p>
                  <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent class="p-0">
                <Command v-model="searchValue">
                  <CommandInput placeholder="Search category..." />
                  <CommandEmpty>No category found.</CommandEmpty>
                  <CommandList>
                    <CommandGroup>
                      <CommandItem
                        v-for="tag in tags"
                        :key="tag.name"
                        :value="tag.name"
                        @select="
                          selectedTag = tag;
                          isPopoverOpen = false;
                          searchValue = '';
                        "
                      >
                        <Check
                          :class="
                            cn(
                              'mr-2 h-4 w-4',
                              searchValue === tag.name
                                ? 'opacity-100'
                                : 'opacity-0'
                            )
                          "
                        />
                        {{ tag.name }}
                      </CommandItem>
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <TagEdit
              v-model:isTagDialogOpen="isTagDialogOpen"
              v-model:tags="tags"
            ></TagEdit>
          </div>
        </div>
      </div>

      <Select
        v-model="selectedVisibility"
        v-if="isEditingCatalog && selectedVisibility"
      >
        <h1 class="text-sm font-bold">Visibility</h1>
        <SelectTrigger class="w-fit">
          <SelectValue class="!flex !items-center gap-2 p-2">
            <div
              v-if="selectedVisibility === Visibility.PUBLIC"
              class="flex items-center gap-1"
            >
              <ArrowUpFromLine class="text-primary" />
              <p class="text-black">Published</p>
            </div>
            <div v-else class="flex items-center gap-1">
              <NotepadTextDashed class="text-primary" />
              <p class="text-black">Draft</p>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem
            v-for="value in visibilityValues"
            :key="value"
            :value="value"
          >
            {{ value === Visibility.PUBLIC ? "Published" : "Draft" }}
          </SelectItem>
        </SelectContent>
      </Select>
      <form
        class="flex flex-col flex-grow text-sm"
        @submit.prevent="updatePrompt"
      >
        <h1 class="mb-4 font-bold">Name</h1>
        <Input v-model="newName" class="w-full ml-2" />
        <h1 class="mt-4 mb-4 font-bold">Description (optional)</h1>
        <textarea
          id="description"
          v-model="newDescription"
          class="mt-1 ml-2 p-2 border border-gray-300 rounded-md w-full resize-none"
          maxlength="10000"
        ></textarea>
        <h1 class="mt-4 mb-4 font-bold">Content</h1>
        <textarea
          id="content"
          v-model="newContent"
          class="mt-1 ml-2 p-2 border border-gray-300 rounded-md resize-none"
          rows="10"
          maxlength="10000"
        ></textarea>
        <div class="flex justify-between pt-4">
          <Button :disabled="!allowSave || isUpdating" type="submit">
            <Loader2 v-if="isUpdating" class="w-4 h-4 mr-2 animate-spin" />
            Save
          </Button>
        </div>
      </form>
    </DialogContent>
  </Dialog>
</template>
