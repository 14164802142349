<script setup lang="ts">
import {
  getSavedPrompts,
  saveSavedPromptOrder,
} from "@/services/saved_prompts";
import { useAuthStore } from "@/stores/auth";
import { useTenantStore } from "@/stores/tenant";
import { SavedPrompt, Skills } from "@/types";
import { computed, onMounted, ref, watch } from "vue";
import { Loader2 } from "lucide-vue-next";
import { useSavedPromptStore } from "@/stores/savedPrompts";
import Catalog from "@/components/Catalog/Catalog.vue";
import { useToolbarStore } from "@/stores/toolbarStore";
import { setUserSkill } from "@/services/skill";
import { useRoute } from "vue-router";
import SavedPromptQuickAccessCard from "./SavedPromptQuickAccessCard.vue";
import Draggable from "vuedraggable";
import SavedPromptDelete from "../SavedPromptDelete.vue";
import SavedPromptEdit from "../SavedPromptEdit.vue";
import SavedPromptCreate from "../SavedPromptCreate.vue";

const tenantStore = useTenantStore();
const auth = useAuthStore();
const savedPromptStore = useSavedPromptStore();
const toolbarStore = useToolbarStore();
const route = useRoute();
const isDeleteDialogOpen = ref(false);
const isEditDialogOpen = ref(false);

const savedPrompts = ref<SavedPrompt[]>([]);
const loadingSavedPrompts = ref(true);
const selectedSavedPrompt = ref<SavedPrompt | null>(null);

const loading = computed(() => {
  return auth.isLoading || loadingSavedPrompts.value;
});

async function loadSavedPrompts() {
  if (!tenantStore.tenantId) {
    return;
  }
  try {
    const orderedPrompts = await getSavedPrompts(tenantStore.tenantId);
    orderedPrompts.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    savedPrompts.value = orderedPrompts.map((elem) => elem.prompt);
  } catch (error) {
    console.error(error);
  } finally {
    loadingSavedPrompts.value = false;
  }
}

const insertPrompt = async (content: string) => {
  if (!content) return;
  savedPromptStore.insertPrompt(content);
};

async function setSelectedSkill(prompt: SavedPrompt): Promise<void> {
  if (
    !prompt.skill_name ||
    prompt.skill_name === Skills.NONE ||
    !tenantStore.tenantId ||
    toolbarStore.selectedSkill === prompt.skill_name
  ) {
    return;
  }

  toolbarStore.setSelectedSkill(prompt.skill_name as Skills);
  const chat_session_id = route.params.session_id as string;
  await setUserSkill(
    tenantStore.tenantId as string,
    prompt.skill_name as string,
    chat_session_id
  );
}

async function handlePromptClick(prompt: SavedPrompt) {
  insertPrompt(prompt.content);
  await setSelectedSkill(prompt);
}

function onDragEnd() {
  saveSavedPromptOrder(
    tenantStore.tenantId as string,
    savedPrompts.value.map((elem) => elem.id)
  );
}

onMounted(async () => {
  if (tenantStore.tenantId) {
    await loadSavedPrompts();
  }
});

watch(
  () => tenantStore.tenantId,
  async (newTenantId) => {
    if (newTenantId) {
      loadingSavedPrompts.value = true;
      await loadSavedPrompts();
    }
  }
);

watch(
  () => toolbarStore.reloadSavedPrompts,
  async (reloadSavedPrompts) => {
    if (reloadSavedPrompts) {
      await loadSavedPrompts();
      toolbarStore.setReloadSavedPrompts(false);
    }
  }
);
</script>
<template>
  <div class="flex items-center justify-between pb-2">
    <h3 class="font-medium">Your use cases</h3>
  </div>
  <div class="saved_prompts_list text-sm list-none p-0 flex flex-col gap-4">
    <Loader2 class="w-4 h-4 animate-spin" v-if="loading" />
    <div class="flex gap-2 w-full">
      <SavedPromptCreate
        v-if="!loading"
        :loadSavedPrompts="loadSavedPrompts"
        :isQuickAccess="true"
      />
      <Catalog v-if="!loading" />
    </div>

    <draggable
      v-if="!loading"
      class="saved_prompts_list flex flex-col gap-4"
      :list="savedPrompts"
      tag="div"
      :options="{ animation: 200 }"
      @end="onDragEnd"
    >
      <template #item="{ element }">
        <SavedPromptQuickAccessCard
          v-model:selectedSavedPrompt="selectedSavedPrompt"
          v-model:isDeleteDialogOpen="isDeleteDialogOpen"
          v-model:isEditDialogOpen="isEditDialogOpen"
          :savedPrompt="element"
          :handlePromptClick="handlePromptClick"
          :loadSavedPrompts="loadSavedPrompts"
        />
      </template>
    </draggable>
  </div>
  <SavedPromptDelete
    v-if="selectedSavedPrompt"
    v-model:isDeleteDialogOpen="isDeleteDialogOpen"
    :savedPrompt="selectedSavedPrompt"
    :loadSavedPrompts="loadSavedPrompts"
  />
  <SavedPromptEdit
    v-if="selectedSavedPrompt"
    v-model:isEditDialogOpen="isEditDialogOpen"
    :savedPrompt="selectedSavedPrompt"
    :loadSavedPrompts="loadSavedPrompts"
    :isEditingCatalog="false"
  />
</template>
