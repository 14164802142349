import apiClient from "@/services/api_client";
import { Skills } from "@/types";

export async function getSkills(tenantId: string) {
  const url = "/skills?tenant_id=" + tenantId;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function getUserSkill(tenantId: string, chat_session_id: string) {
  const url =
    "/skill/selected?tenant_id=" +
    tenantId +
    "&chat_session_id=" +
    chat_session_id;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function setUserSkill(
  tenantId: string,
  skill: string,
  chatSessionId: string
) {
  const url = "/skill/selected";
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    skill: skill,
    chat_session_id: chatSessionId,
  });
  return await response;
}

export function getSkillColor(skill: string | undefined) {
  switch (skill) {
    case Skills.CHAT_WITH_DOCUMENTS:
      return "bg-blue-100";
    case Skills.CHAT_WITH_LLM:
      return "bg-yellow-100";
    case Skills.CHAT_WITH_WEB:
      return "bg-green-100";
    case Skills.O1_CHAIN:
      return "bg-orange-100";
    default:
      return "bg-gray-100";
  }
}
