<script setup lang="ts">
import { Skills } from "@/types";
import { MoveLeft } from "lucide-vue-next";
import DataSourceSelectorMenu from "./DataSourceSelectorMenu.vue";

const emit = defineEmits<{
  (e: "setSelectedSkill", skill: Skills): void;
}>();
</script>

<template>
  <div class="data_source_selection flex flex-col h-full pt-10">
    <div
      class="flex items-center gap-2 cursor-pointer group text-muted-foreground hover:text-foreground max-w-[860px] px-8 mb-12"
      @click="emit('setSelectedSkill', Skills.NONE)"
    >
      <MoveLeft class="w-4 h-4" />
      <p class="text-sm group-hover:underline">Back to agent selection</p>
    </div>
    <div
      class="flex-1 flex flex-col gap-8 justify-center items-center max-w-[860px] px-8 m-auto"
    >
      <div class="welcome_message font-spartan text-center">
        <h1 class="text-4xl mb-2">Choose data sources</h1>
        <h2 class="text-2xl font-light">
          Select the documents you want to chat with
        </h2>
      </div>
      <DataSourceSelectorMenu />
    </div>
  </div>
</template>
