<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { createSavedPrompt } from "@/services/saved_prompts";
import { getSkills } from "@/services/skill";
import { getTags } from "@/services/tags";
import { useTenantStore } from "@/stores/tenant";
import { Skill, Skills, Tag, Visibility } from "@/types";
import {
  ArrowUpFromLine,
  Brain,
  Check,
  ChevronsUpDown,
  Lightbulb,
  Loader2,
  NotepadTextDashed,
  Plus,
  SquarePen,
} from "lucide-vue-next";
import { computed, onMounted, ref, watch } from "vue";
import TagEdit from "./TagEdit.vue";

const props = defineProps<{
  loadSavedPrompts: Function;
  isQuickAccess: boolean;
}>();

const tenantStore = useTenantStore();

const newSavedPromptName = ref<string>("");
const newSavedPromptContent = ref<string>("");
const newSavedPromptDescription = ref<string>("");

const visibilityValues = Object.values(Visibility);

const selectedVisibility = ref<string>(Visibility.PRIVATE);
const skills = ref<Skill[]>([]);
const selectedSkillName = ref<string>(Skills.CHAT_WITH_DOCUMENTS);

const noAgentSelectedName = "Manually selected agent";
const errorMessage = ref<string | null>(null);
const isDialogOpen = ref(false);
const isLoading = ref(false);

const isTagDialogOpen = ref(false);
const selectedTag = ref<Tag | undefined>(undefined);
const tags = ref<Tag[]>([]);
const isPopoverOpen = ref(false);
const searchValue = ref("");

const selectedTenant = computed<string>(() =>
  props.isQuickAccess ? (tenantStore.tenantId as string) : "__global__"
);

async function handleCreateSavedPrompt() {
  if (!selectedTenant.value || (!selectedTag.value && !props.isQuickAccess)) {
    return;
  }
  isLoading.value = true;

  await createSavedPrompt(
    newSavedPromptName.value,
    newSavedPromptContent.value,
    selectedVisibility.value,
    selectedTenant.value,
    selectedTag.value?.id,
    newSavedPromptDescription.value,
    selectedSkillName.value
  );

  await props.loadSavedPrompts();

  newSavedPromptName.value = "";
  newSavedPromptContent.value = "";
  newSavedPromptDescription.value = "";
  isDialogOpen.value = false;
  isLoading.value = false;
}

const selectedSkillDescription = computed(() => {
  const selectedSkill = skills.value.find(
    (skill) => skill.name === selectedSkillName.value
  );
  return selectedSkill?.description;
});

onMounted(async () => {
  if (!selectedTenant.value) {
    return;
  }
  if (!props.isQuickAccess) {
    tags.value = await getTags(selectedTenant.value);
  }
  const response = await getSkills(selectedTenant.value);
  skills.value = response.skills;
  skills.value.push({
    name: noAgentSelectedName,
    description: "No agent automatically selected",
  });
});

watch(newSavedPromptName, (newName) => {
  if (!newName) {
    errorMessage.value = "Name cannot be empty.";
    return;
  }
  errorMessage.value = null;
});
</script>

<template>
  <Dialog v-model:open="isDialogOpen">
    <DialogTrigger asChild>
      <Button
        v-if="props.isQuickAccess"
        class="w-full h-12"
        variant="secondary"
      >
        <SquarePen class="w-5 h-5 mr-2" />
        <p>Create</p>
      </Button>
      <div
        v-else
        class="datasource_card_create w-full min-h-48 flex items-center justify-center gap-4 rounded-lg bg-primary20 border border-primary30 cursor-pointer transition-colors duration-200 ease-in-out hover:bg-primary30"
      >
        <div class="p-4 w-full text-center">
          <Plus class="w-6 h-6 text-foreground mx-auto" />
        </div>
      </div>
    </DialogTrigger>
    <DialogContent class="flex flex-col h-4/5 overflow-y-auto">
      <DialogHeader>
        <DialogTitle
          >Create {{ props.isQuickAccess ? "your own" : "catalog" }} use
          case</DialogTitle
        >
      </DialogHeader>
      <DialogDescription>
        <!-- AGENT -->
        <label for="skill" class="block text-sm font-medium text-gray-700"
          >Agent</label
        >
        <form @submit.prevent="handleCreateSavedPrompt">
          <div class="p-2 pb-4 flex items-center gap-4">
            <Select v-model="selectedSkillName" class="shrink-0">
              <SelectTrigger class="w-auto">
                <SelectValue class="!flex !items-center gap-2 p-2">
                  <Brain class="text-primary shrink-0 w-5 h-5" />
                  <p class="text-black">
                    {{ selectedSkillName }}
                  </p>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem
                  v-for="skill in skills"
                  :key="skill.name"
                  :value="skill.name"
                >
                  <p
                    :class="[
                      'flex items-center gap-1',
                      skill.name === noAgentSelectedName
                        ? 'text-muted-foreground'
                        : '',
                    ]"
                  >
                    {{ skill.name }}
                  </p>
                </SelectItem>
              </SelectContent>
            </Select>
            <p>{{ selectedSkillDescription }}</p>
          </div>
          <!-- CATEGORY -->
          <div v-if="!props.isQuickAccess">
            <label for="skill" class="block text-sm font-medium text-gray-700"
              >Category</label
            >
            <div class="flex items-center gap-2 mt-2 mb-4">
              <Popover v-model:open="isPopoverOpen">
                <PopoverTrigger as-child>
                  <Button
                    variant="outline"
                    role="combobox"
                    :aria-expanded="isPopoverOpen"
                    class="justify-between"
                  >
                    <p class="text-black">
                      {{ selectedTag?.name || "Select category" }}
                    </p>
                    <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent class="p-0">
                  <Command v-model="searchValue">
                    <CommandInput placeholder="Search category..." />
                    <CommandEmpty>No category found.</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        <CommandItem
                          v-for="tag in tags"
                          :key="tag.name"
                          :value="tag.name"
                          @select="
                            selectedTag = tag;
                            isPopoverOpen = false;
                            searchValue = '';
                          "
                        >
                          <Check
                            :class="
                              cn(
                                'mr-2 h-4 w-4',
                                searchValue === tag.name
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )
                            "
                          />
                          {{ tag.name }}
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <TagEdit
                v-model:isTagDialogOpen="isTagDialogOpen"
                v-model:tags="tags"
              ></TagEdit>
            </div>
          </div>
          <!-- NAME -->
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              v-model="newSavedPromptName"
              class="mt-1 p-2 border border-gray-300 rounded-md w-full ml-2"
              required
              autocomplete="off"
            />
            <p v-if="errorMessage" class="text-red-500 text-sm">
              {{ errorMessage }}
            </p>
          </div>
          <!-- DESCRIPTION -->
          <div>
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
              >Description (optional)</label
            >
            <textarea
              id="description"
              v-model="newSavedPromptDescription"
              class="description_textarea border mt-1 p-2 border-gray-300 rounded-md w-full ml-2 resize-none"
              rows="2"
              maxlength="10000"
              placeholder="Enter description"
              autocomplete="off"
            ></textarea>
          </div>
          <!-- CONTENT -->
          <div>
            <label
              for="content"
              class="mt-2 block text-sm font-medium text-gray-700"
              >Content</label
            >
            <textarea
              id="content"
              v-model="newSavedPromptContent"
              class="description_textarea mt-1 p-2 border border-gray-300 rounded-md w-full ml-2 resize-none"
              rows="10"
              maxlength="10000"
              placeholder="Enter your prompt"
              required
              autocomplete="off"
            ></textarea>
          </div>
          <!-- VISIBILITY -->
          <div class="flex gap-2">
            <div v-if="!props.isQuickAccess" class="mt-4">
              <Select v-model="selectedVisibility">
                <SelectTrigger class="w-auto">
                  <SelectValue class="!flex !items-center gap-2 p-2">
                    <div
                      v-if="selectedVisibility === Visibility.PUBLIC"
                      class="flex items-center gap-1"
                    >
                      <ArrowUpFromLine class="text-primary" />
                      <p class="text-black">Publish</p>
                    </div>
                    <div v-else class="flex items-center gap-1">
                      <NotepadTextDashed class="text-primary" />
                      <p class="text-black">Draft</p>
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem
                    v-for="value in visibilityValues"
                    :key="value"
                    :value="value"
                  >
                    <p>
                      {{ value === Visibility.PUBLIC ? "Publish" : "Draft" }}
                    </p>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div v-if="!props.isQuickAccess" class="flex !items-center gap-1 p-3">
            <Lightbulb class="w-4 h-4 text-yellow-500 flex-none" />
            <p
              v-if="selectedVisibility === Visibility.PRIVATE"
              class="text-xs text-gray-500"
            >
              This prompt won't be displayed in the catalog.
            </p>
            <p
              v-else-if="selectedVisibility === Visibility.PUBLIC"
              class="text-xs text-gray-500"
            >
              This prompt will be published in the catalog.
            </p>
          </div>
          <DialogFooter class="pt-4">
            <Button variant="outline" @click="isDialogOpen = false"
              >Cancel</Button
            >
            <Button
              v-if="!isLoading"
              type="submit"
              :disabled="
                newSavedPromptName === '' ||
                newSavedPromptContent === '' ||
                (!props.isQuickAccess && !selectedTag)
              "
              >Create</Button
            >
            <Button v-else disabled>
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
              Create
            </Button>
          </DialogFooter>
        </form>
      </DialogDescription>
    </DialogContent>
  </Dialog>
</template>
