<script setup lang="ts">
import { ref, watch, watchEffect, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { useTenantStore } from "@/stores/tenant";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const user = useUserStore();

const auth = useAuthStore();
const tenantStore = useTenantStore();

const getTenants = computed(() => {
  return user.tenants;
});

const selectedTenant = ref<string>();

// This watchEffect is needed to properly setup the current tenant after a login
// because watch(user) does not work (this probably means that we do not use descope correctly )
watchEffect(async () => {
  if (selectedTenant.value !== undefined) {
    return;
  }

  if (!auth.isAuthenticated) {
    return;
  }
  if (user.tenants !== undefined) {
    const tenants = user.tenants;
    if (tenants && tenants.length > 0) {
      selectedTenant.value = tenants[0].tenantName;
      tenantStore.updateTenant(tenants[0]);
    }
  }
});

watch(selectedTenant, async (tenantName) => {
  const tenants = user.tenants;
  if (!tenants) {
    return;
  }

  const tenant = tenants.find(
    (tenant: any) => tenant.tenantName === tenantName
  );
  if (tenant) {
    tenantStore.updateTenant(tenant);

    if (typeof route.meta.canAccess === "function") {
      if (!route.meta.canAccess(tenantStore)) {
        router.push("/unauthorized");
      }
    } else {
      console.warn(
        "route.meta.canAccess is not defined or is not a function",
        route.meta
      );
    }
  }
});
</script>

<template>
  <Select v-model="selectedTenant" v-if="getTenants && getTenants.length > 1">
    <SelectTrigger class="bg-transparent border-primary50">
      <SelectValue>
        {{ selectedTenant }}
      </SelectValue>
    </SelectTrigger>
    <SelectContent>
      <SelectItem
        v-for="tenant in getTenants"
        :key="tenant.tenantId"
        :value="tenant.tenantName"
      >
        {{ tenant.tenantName }}
      </SelectItem>
    </SelectContent>
  </Select>
</template>
