<script setup lang="ts" generic="TData, TValue">
import { ref, watch, onMounted } from "vue";
import type { ColumnDef } from "@tanstack/vue-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FlexRender, getCoreRowModel, useVueTable } from "@tanstack/vue-table";
import DatatablePagination from "./DatatablePagination.vue";

export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

type DatatableProps = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  count: number;
  pagination: PaginationState;
  loading: boolean;
};

const { columns, data, count, pagination, loading } =
  defineProps<DatatableProps>();

const emit = defineEmits<{
  (e: "update:pagination", pagination: PaginationState): void;
  (e: "deleteItem", item: TData): void;
}>();

const tableData = ref<TData[]>([]);

const localPagination = ref<PaginationState>({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
});

const table = useVueTable({
  get data() {
    return tableData.value as TData[];
  },
  columns,
  getCoreRowModel: getCoreRowModel(),
  manualPagination: true,
  get pageCount() {
    return Math.ceil(count / pagination.pageSize);
  },
  onPaginationChange: (updater) => {
    const newPagination =
      typeof updater === "function" ? updater(localPagination.value) : updater;

    localPagination.value = newPagination;
    updatePagination(newPagination);
  },
  state: {
    get pagination() {
      return localPagination.value;
    },
  },
  meta: {
    handleDelete: (item: TData) => emit("deleteItem", item),
  } as const,
});

watch(
  () => data,
  (newData) => {
    if (JSON.stringify(newData) !== JSON.stringify(tableData.value)) {
      tableData.value = newData;
    }
  },
  { immediate: true }
);

watch(
  () => pagination,
  (newPagination) => {
    if (
      JSON.stringify(newPagination) !== JSON.stringify(localPagination.value)
    ) {
      localPagination.value = {
        pageIndex: newPagination.pageIndex,
        pageSize: newPagination.pageSize,
      };
    }
  },
  { deep: true }
);

let paginationTimeout: ReturnType<typeof setTimeout> | null = null;
const updatePagination = (newPagination: PaginationState) => {
  if (paginationTimeout) {
    clearTimeout(paginationTimeout);
  }

  paginationTimeout = setTimeout(() => {
    emit("update:pagination", newPagination);
  }, 0);
};

onMounted(() => {
  tableData.value = data;
});
</script>

<template>
  <div class="datatable flex flex-col relative">
    <div
      v-if="loading"
      class="absolute top-0 left-0 w-full h-1 bg-gray-100 overflow-hidden rounded-t-md"
    >
      <div
        class="h-full bg-primary transition-all duration-300 ease-in-out animate-progress"
      ></div>
    </div>

    <div
      class="table border rounded-md transition-opacity duration-300"
      :class="{ 'opacity-50': loading }"
    >
      <Table>
        <TableHeader>
          <TableRow
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <TableHead v-for="header in headerGroup.headers" :key="header.id">
              <FlexRender
                v-if="!header.isPlaceholder"
                :render="header.column.columnDef.header"
                :props="header.getContext()"
              />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <template v-if="table.getRowModel().rows?.length">
            <TableRow
              v-for="row in table.getRowModel().rows"
              :key="row.id"
              :data-state="row.getIsSelected() ? 'selected' : undefined"
            >
              <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
              </TableCell>
            </TableRow>
          </template>
          <template v-else>
            <TableRow>
              <TableCell :colspan="columns.length" class="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          </template>
        </TableBody>
      </Table>
    </div>
    <DatatablePagination :table="table" :loading="loading" />
  </div>
</template>

<style scoped>
@keyframes progress {
  0% {
    width: 0%;
    opacity: 1;
  }
  50% {
    width: 70%;
    opacity: 0.8;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

.animate-progress {
  animation: progress 1s ease-in-out infinite;
}
</style>
