import apiClient from "@/services/api_client";
import { Tag } from "@/types";

export async function createTag(name: string, tenantId: string) {
  const url = "/tags";
  return await apiClient.post(url, {
    tenant_id: tenantId,
    name: name,
  });
}

export async function getTags(tenantId: string): Promise<Tag[]> {
  // this isn't mistake, naming the endpoint "tags" here generates an error
  const url = "/tags-endpoint?tenant_id=" + tenantId;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function deleteTag(tagId: number, tenantId: string) {
  const url = "/tags";
  await apiClient.delete(url, {
    id: tagId,
    tenant_id: tenantId,
  });
}

export async function updateTag(tagId: number, name: string, tenantId: string) {
  const url = "/tags/update";
  return await apiClient.post(url, {
    id: tagId,
    name: name,
    tenant_id: tenantId,
  });
}
