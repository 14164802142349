<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useApiRequest } from "@/composables/useApiRequest";
import { getPublicCatalogPrompts } from "@/services/saved_prompts";
import { CirclePlus, Loader2, ChevronDown, ChevronUp } from "lucide-vue-next";
import { onMounted, ref, computed } from "vue";
import CatalogSavedPromptCard from "./CatalogSavedPromptCard.vue";
import { SavedPromptWithMetadata } from "@/types";

const {
  loading,
  data: catalogSavedPromptWithMetadata,
  execute,
} = useApiRequest<SavedPromptWithMetadata[]>();
const isCatalogOpen = ref<boolean>(false);

const expandedTags = ref<Record<string, boolean>>({});

function toggleShowMore(tagName: string) {
  expandedTags.value[tagName] = !expandedTags.value[tagName];
}

async function loadSavedPrompts() {
  await execute(() => getPublicCatalogPrompts());
}

onMounted(async () => {
  await loadSavedPrompts();
});

const groupedCatalogPrompts = computed(() => {
  if (!catalogSavedPromptWithMetadata.value) return {};

  return catalogSavedPromptWithMetadata.value.reduce(
    (acc, item) => {
      const tagName = item.tags?.[0]?.name ?? "No Tag";

      if (!acc[tagName]) {
        acc[tagName] = [];
      }
      acc[tagName].push(item);
      return acc;
    },
    {} as Record<string, SavedPromptWithMetadata[]>
  );
});
</script>

<template>
  <Dialog v-model:open="isCatalogOpen">
    <DialogTrigger as-child>
      <Button :disabled="loading" class="h-12" variant="secondary">
        <CirclePlus class="w-5 h-5 mr-2" />
        <p>Add from catalog</p>
      </Button>
    </DialogTrigger>
    <DialogContent class="h-5/6 !max-w-none w-5/6 p-6">
      <div class="relative h-full overflow-auto p-6">
        <Loader2 v-if="loading" class="animate-spin h-5 w-5 mt-4" />
        <div v-else>
          <div
            v-for="(items, tagName) in groupedCatalogPrompts"
            :key="tagName"
            class="mb-6"
          >
            <h2 class="mb-4 font-bold">{{ tagName }}</h2>
            <transition-group
              name="fade"
              tag="div"
              class="prompts_list_container grid grid-cols-3 gap-4"
            >
              <CatalogSavedPromptCard
                v-for="item in items.slice(
                  0,
                  expandedTags[tagName] ? items.length : 3
                )"
                :key="item.prompt.id"
                :savedPrompt="item.prompt"
                :tags="item.tags ?? []"
                @close="isCatalogOpen = false"
              />
            </transition-group>
            <Button
              v-if="items.length > 3"
              class="inline-flex items-center mt-4 ml-2 text-primary200"
              variant="link"
              @click="toggleShowMore(tagName)"
            >
              {{ expandedTags[tagName] ? "Show less" : "Show more" }}
              <ChevronDown v-if="!expandedTags[tagName]" class="w-4 h-4 ml-1" />
              <ChevronUp v-else class="w-4 h-4 ml-1" />
            </Button>
          </div>
        </div>
      </div>
      <DialogFooter class="!justify-center text-xs text-muted-foreground">
        <p>Click to add a use case to your list</p>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
