import { IndexingStatus, Item } from "@/types";
import { ColumnDef } from "@tanstack/table-core";
import { Loader2, Trash } from "lucide-vue-next";
import { h } from "vue";

declare module "@tanstack/table-core" {
  interface TableMeta<TData> {
    handleDelete: (item: TData) => void;
  }
}

export const columns: ColumnDef<Item>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => h("p", { class: "text-left" }, row.getValue("name")),
  },
  {
    accessorKey: "indexing_status",
    header: () => h("p", { class: "text-center" }, "Indexing status"),
    cell: ({ row }) => {
      switch (row.getValue("indexing_status")) {
        case IndexingStatus.ERROR:
          return h("div", { class: "text-center text-destructive" }, "Error");
        case IndexingStatus.PENDING:
          return h(
            "div",
            { class: "text-center text-alert animate-pulse" },
            "Pending"
          );
        case IndexingStatus.PROCESSING:
          return h(
            "div",
            {
              class: "flex items-center justify-center w-full text-center",
            },
            [
              h(Loader2, { class: "w-4 h-4 mr-2 animate-spin text-alert" }),
              h("p", { class: "text-alert" }, "Progressing"),
            ]
          );
        default:
          return h("div", { class: "text-center text-primary" }, "Indexed");
      }
    },
  },
  {
    id: "actions",
    cell: ({ row, table }) => {
      return h("div", { class: "flex justify-end" }, [
        h(
          "button",
          {
            class: "p-2 hover:bg-gray-100 rounded-full",
            onClick: () => {
              const tableInstance = table.options;
              if (typeof tableInstance.meta?.handleDelete === "function") {
                tableInstance.meta.handleDelete(row.original);
              }
            },
          },
          [
            h(Trash, {
              class: "w-4 h-4 text-gray-500 hover:text-red-500",
            }),
          ]
        ),
      ]);
    },
  },
];
