<script setup lang="ts">
import { ref, computed } from "vue";
import {
  Loader2,
  OctagonAlert,
  ListChecks,
  RotateCcw,
  Copy,
  Check,
  SquareStack,
  Download,
} from "lucide-vue-next";

import Button from "@/components/ui/button/Button.vue";
import O1Loader from "@/components/O1Loader.vue";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import SourcesDisplay from "@/components/SourcesDisplay.vue";
import FlowAccordion from "@/components/ChatView/flow/FlowAccordion.vue";

import logo from "@/assets/logo-black.png";
import { useConfigCatStore } from "@/stores/configCat";
import { parseMessage } from "@/lib/messageFormater";
import { exportMessage } from "@/services/export_message";
import { useToast } from "@/components/ui/toast/use-toast";
import { eventCopyMessage, eventExportMessage } from "@/services/event";
import { useRoute } from "vue-router";
import MessageFile from "./MessageFile.vue";

const props = defineProps<{
  sources: any;
  displayLastBotButtons: boolean;
  index: number;
  message?: string;
  type?: string;
  isLoading?: boolean;
  tenantId?: string;
  flowId?: string;
  fileName?: string;
  messageId?: string;
}>();

const toast = useToast();

const configCatStore = useConfigCatStore();
const messageContent = ref<HTMLElement | null>(null);
const isCopied = ref(false);
const route = useRoute();

const chatSessionId = ref<string | undefined>(
  route.params.session_id as string
);

const copyButtonClasses = computed(() => {
  const baseClasses = [
    "h-fit",
    "aspect-square",
    "rounded-lg",
    "transition-colors",
    "duration-300",
  ];
  if (isCopied.value) {
    return [
      ...baseClasses,
      "bg-muted-foreground",
      "text-primary20",
      "hover:bg-muted-foreground",
    ];
  } else {
    return [
      ...baseClasses,
      "bg-primary20",
      "text-muted-foreground",
      "hover:bg-primary30",
    ];
  }
});

const messageClass = computed(() => {
  if (props.type === "user") {
    return "bg-primary20 p-4 rounded-lg border-0 w-fit";
  }
  if (props.type === "bot" || props.type === "error") {
    return "flex gap-4 w-full";
  }
});

const parsedMessage = computed(() => {
  const message = props.message || "";
  return parseMessage(message);
});

const showLoadingSpinner = computed(() => {
  return (
    props.isLoading ||
    (props.message && props.message.trim() === "...") ||
    (props.message && props.message.trim() === "")
  );
});

const showLoadingSpinnerO1 = computed(() => {
  return props.message && props.message.trim() === "waiting o1";
});

const shouldShowMenuButtons = computed(() => {
  return (props.type === "bot" || props.type === "error") && !props.flowId;
});

const showMessageContent = computed(() => {
  return (
    props.message &&
    !props.flowId &&
    props.message !== "flow created in db" &&
    !showLoadingSpinner.value &&
    !showLoadingSpinnerO1.value
  );
});

const handleMessageCopy = async () => {
  if (messageContent.value) {
    const htmlContent = messageContent.value.innerHTML;
    const markdownContent = props.message || "";
    if (navigator.clipboard && navigator.clipboard.write) {
      const items = {
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob([markdownContent], { type: "text/plain" }),
      };
      const clipboardItem = new ClipboardItem(items);
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          isCopied.value = true;
          setTimeout(() => {
            isCopied.value = false;
          }, 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback pour les anciens navigateurs
      const textarea = document.createElement("textarea");
      textarea.value = markdownContent;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand("copy");
        isCopied.value = true;
        setTimeout(() => {
          isCopied.value = false;
        }, 2000);
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  }
  if (props.tenantId && chatSessionId.value) {
    await eventCopyMessage(
      props.messageId || "",
      props.tenantId,
      props.message || "",
      chatSessionId.value
    );
  }
};

const handleExport = async () => {
  if (messageContent.value) {
    const htmlContent = messageContent.value.innerHTML;
    const tenantId = props.tenantId;
    if (!tenantId) {
      console.error("Tenant ID is missing");
      return;
    }
    try {
      const blob = await exportMessage(tenantId, htmlContent);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "playground_exported_message.docx";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.toast({
        title: "Export issue",
        description: "Something failed during the export",
        variant: "destructive",
        duration: 3000,
      });
    }
  }
  if (props.tenantId && chatSessionId.value) {
    await eventExportMessage(
      props.messageId || "",
      props.tenantId,
      props.message || "",
      chatSessionId.value
    );
  }
};
</script>

<template>
  <div class="message_container my-4 max-w-2xl break-words">
    <div class="message leading-7 text-sm" :class="messageClass">
      <Avatar
        class="playground_bot shrink-0 p-2 bg-background"
        v-if="props?.type === 'bot'"
      >
        <AvatarImage alt="Playground" :src="logo" />
        <AvatarFallback>PG</AvatarFallback>
      </Avatar>
      <div v-if="props?.type === 'error'" class="flex p-1">
        <OctagonAlert class="shrink-0 rounded-full w-7 h-7 bg-yellow-500 p-1" />
      </div>
      <div v-if="showLoadingSpinner" class="flex items-center space-x-2">
        <Loader2 class="animate-spin h-5 w-5" />
      </div>
      <FlowAccordion
        v-else-if="props.flowId"
        :flowId="props.flowId"
        v-if="props.flowId"
      />
      <O1Loader v-else-if="showLoadingSpinnerO1" :isOn="showLoadingSpinnerO1" />

      <MessageFile
        v-else-if="props.fileName"
        :fileName="props.fileName"
        :content="props.message || ''"
      ></MessageFile>

      <div v-else class="message_content flex-1 w-full flex flex-col gap-3">
        <!-- Message content -->
        <div
          v-if="showMessageContent"
          v-html="parsedMessage"
          ref="messageContent"
        ></div>
        <!-- Message toolbar -->
        <div
          v-if="shouldShowMenuButtons"
          class="message_toolbar flex items-center space-x-2"
        >
          <!-- sources Button -->
          <SourcesDisplay :sources="props.sources" :tenantId="props.tenantId">
            <TooltipProvider v-if="props.type === 'bot' && props.sources">
              <Tooltip>
                <TooltipTrigger as-child>
                  <Button
                    class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                    size="xs"
                  >
                    <ListChecks :size="16" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>View sources</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </SourcesDisplay>

          <!-- Copy Button -->
          <TooltipProvider
            v-if="props?.type === 'bot' && props.displayLastBotButtons"
          >
            <Tooltip :open="isCopied ? true : undefined">
              <TooltipTrigger as-child>
                <Button
                  :class="copyButtonClasses"
                  size="xs"
                  @click="handleMessageCopy"
                >
                  <component :is="isCopied ? Check : Copy" :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {{ isCopied ? "Copied!" : "Copy answer" }}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <!-- Retry Button -->
          <TooltipProvider v-if="props.displayLastBotButtons">
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                  size="xs"
                  @click="$emit('retry', props.index)"
                >
                  <RotateCcw :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Try again</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <!-- Magic Button -->
          <TooltipProvider
            v-if="
              props.displayLastBotButtons && configCatStore.buttonCreateFlow
            "
          >
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                  size="xs"
                  @click="$emit('createFlow', props.index)"
                >
                  <SquareStack :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Enhanced Content Breakdown</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <!-- 3) Export Button  -->
          <TooltipProvider v-if="props.displayLastBotButtons">
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                  size="xs"
                  @click="handleExport"
                >
                  <Download :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Export as .docx</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  </div>
</template>
