<script setup lang="ts">
import { routes } from "@/router";
import { useDescope } from "@descope/vue-sdk";
import { Button } from "@/components/ui/button";
import Version from "@/components/Version.vue";
import { LogOutIcon, Milestone, CircleUserRound } from "lucide-vue-next";
import { useTenantStore } from "@/stores/tenant";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import Separator from "../ui/separator/Separator.vue";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useConfigCatStore } from "@/stores/configCat";
import { useUserStore } from "@/stores/user";

const tenantStore = useTenantStore();
const user = useUserStore();
const auth = useAuthStore();
const { logout } = useDescope();
const router = useRouter();
const configCatStore = useConfigCatStore();

const isUserMenuOpen = ref<boolean>(false);

const userMenuRoutes = computed(() => {
  return routes.filter((route) => {
    if (route.path === "/user-management") {
      return (
        configCatStore.userManagementTab &&
        tenantStore.tenantAdmin &&
        !auth.isInsideTeams
      );
    } else {
      return route.meta?.showInUserMenu;
    }
  });
});

const routing = (routePath: string) => {
  isUserMenuOpen.value = false;
  router.push(routePath);
};
</script>

<template>
  <Popover v-model:open="isUserMenuOpen">
    <PopoverTrigger class="w-full">
      <Button
        variant="link"
        class="user_menu w-full flex gap-2 items-center transition-all px-3 h-fit text-primary50 hover:text-background hover:bg-foreground90"
      >
        <CircleUserRound class="h-6 w-6" />
        <p class="truncate">{{ user.email }}</p>
      </Button>
    </PopoverTrigger>
    <PopoverContent class="flex flex-col gap-3 items-start px-2 py-3">
      <div class="user_menu_entries w-full flex flex-col gap-1 items-start">
        <Button
          variant="ghost"
          class="w-full flex gap-2 hover:bg-primary20 justify-start transition-all text-muted-foreground"
          v-for="route in userMenuRoutes"
          @click="routing(route.path)"
          :key="route.name"
        >
          <component :is="route.meta?.icon" class="h-5 w-5" />{{ route.name }}
        </Button>
        <Separator />
        <Button
          variant="ghost"
          v-if="!auth.isInsideTeams"
          class="flex hover:bg-primary20 justify-start w-full items-center gap-2 transition-all text-muted-foreground h-fit"
          @click="logout()"
        >
          <LogOutIcon class="h-4 w-4" />
          Logout
        </Button>
        <Separator />
      </div>
      <Button
        variant="link"
        v-if="tenantStore.tenantElqano"
        class="flex items-center gap-2 transition-all text-placeholder text-xs h-fit px-3"
      >
        <Milestone class="h-4 w-4" />
        <Version />
      </Button>
    </PopoverContent>
  </Popover>
</template>
