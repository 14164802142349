<script setup lang="ts">
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { SelectViewport } from "radix-vue";
import DropZone from "@/components/DropZone.vue";
import {
  Check,
  CloudUpload,
  LayoutList,
  Loader2,
  Paperclip,
} from "lucide-vue-next";
import { acceptExtensionValue } from "@/services/upload_document";
import { computed, onMounted, ref, watch } from "vue";
import { uploadFile } from "@/services/upload_document";
import { selectTemplate, storeTemplate } from "@/services/templates";
import { createDataSource } from "@/services/data_source";
import { getDataSources, getTemplates } from "@/services/data_source";
import { Visibility } from "@/types";
import { Item } from "@/types";
import { useApiRequest } from "@/composables/useApiRequest";
import { useUserStore } from "@/stores/user";
import DataSourceSelectorMenuEntry from "@/components/ChatView/guided-agent-selection/DataSourceSelectorMenuEntry.vue";

const user = useUserStore();

type Props = {
  tenantId: string | undefined;
  isDropZone?: boolean;
};
const { tenantId, isDropZone } = withDefaults(defineProps<Props>(), {
  isDropZone: true,
});

const emit = defineEmits<{
  (event: "onSelection", datasourceId: number, templateName: string): void;
}>();

const fileInput = ref<HTMLInputElement | null>(null);
const templatesList = ref<Item[] | null>(null);
const templateDatasourceId = ref<number | null>(null);
const selectedTemplateId = ref<string | undefined>(undefined);
const errorCreateTemplateDataSource = ref<string | null>(null);

const selectedTemplateName = computed(() => {
  if (!selectedTemplateId.value || !templatesList.value) return "";
  const template = templatesList.value.find(
    (t) => t.datasource_item_id === selectedTemplateId.value
  );
  return template ? template.name : "";
});

const { data: templateDataSourceId, execute: fetchDataSources } =
  useApiRequest<{ id: number }[]>();

const {
  data: templatesData,
  loading: loadingTemplates,
  error: errorTemplates,
  execute: fetchTemplates,
} = useApiRequest<{ items: Item[]; data_source_id: number }>();

const fetchTemplateDatasource = async () => {
  if (!tenantId) return;
  await fetchDataSources(() => getDataSources(tenantId, true));
};

// Watcher pour récupérer les templates lorsque templateDataSourceId est récupéré
watch(templateDataSourceId, async (dataSources) => {
  if (dataSources && dataSources.length) {
    const dataSourceId = dataSources[0].id;
    templateDatasourceId.value = dataSourceId;
    await fetchTemplates(() => getTemplates(tenantId!, dataSourceId));
  }
});

// Watcher pour mettre à jour les templates list
watch(templatesData, (templates) => {
  if (templates) {
    templatesList.value = templates.items ?? null;
    templateDatasourceId.value = templates.data_source_id ?? null;
  }
});

// Désactiver tous les templates après le fetch pour ne pas afficher le <Check /> au montage
watch(templatesList, (templates) => {
  if (templates) {
    templates.forEach((template) => {
      template.active = false;
    });
  }
});

const onDropFiles = (files: FileList) => {
  handleUploadFile(files[0]);
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const onSelectFile = () => {
  if (!fileInput.value) return;
  const files = fileInput.value.files;
  if (!files) return;
  handleUploadFile(files[0]);
};

const handleTemplateSelection = async (dataSourceItemId: string) => {
  if (!tenantId || !templateDatasourceId.value) return;
  try {
    if (templatesList.value) {
      templatesList.value = templatesList.value.map((template) => ({
        ...template,
        active: template.datasource_item_id === dataSourceItemId,
      }));
    }

    selectedTemplateId.value = dataSourceItemId;

    await selectTemplate(
      tenantId,
      templateDatasourceId.value,
      dataSourceItemId
    );

    emit("onSelection", templateDatasourceId.value, selectedTemplateName.value);
  } catch (e) {
    console.error(e);
  }
};

const handleUploadFile = async (file: File) => {
  if (!tenantId || !file || !user.userId) return;
  try {
    if (!templateDatasourceId.value) {
      const { id } = await createDataSource(
        `_templates_data_source_${user.userId}`,
        tenantId,
        Visibility.PRIVATE,
        "Templates data source",
        true
      );
      templateDatasourceId.value = id;
    }

    const filePath = await uploadFile(
      file,
      tenantId,
      1,
      1,
      String(templateDatasourceId.value)
    );

    if (!filePath) return;
    const dataSourceItem = await storeTemplate(
      tenantId,
      templateDatasourceId.value,
      filePath,
      file.name
    );

    if (!dataSourceItem) return;
    await selectTemplate(
      tenantId,
      templateDatasourceId.value,
      dataSourceItem.id
    );

    selectedTemplateId.value = dataSourceItem.id;

    await fetchTemplateDatasource();
    emit("onSelection", templateDatasourceId.value, file.name);
  } catch (error) {
    if (error instanceof Error) {
      errorCreateTemplateDataSource.value = error.message;
    } else {
      errorCreateTemplateDataSource.value = "An unexpected error occurred.";
    }
    console.error(error);
  }
};

onMounted(() => {
  fetchTemplateDatasource();
});
</script>

<template>
  <div class="teamplate_picker w-full flex flex-col gap-4">
    <!-- DropZone when isDropZone is True -->
    <div v-if="isDropZone" class="w-full">
      <div class="dropzone-container w-full flex flex-col gap-2">
        <div
          class="h-60 w-full flex flex-col gap-2 justify-center items-center rounded-lg border border-dashed border-primary40 bg-primary10"
        >
          <DropZone
            :multiple="false"
            @files-dropped="onDropFiles"
            class="flex flex-col gap-4 items-center justify-center"
          >
            <div
              class="rounded-full bg-primary30 p-3 cursor-pointer hover:bg-primary40 transition-colors duration-200"
              @click="triggerFileInput"
            >
              <CloudUpload class="w-6 h-6 text-foreground" />
            </div>
            <div class="text-center leading-8">
              <h4 class="text-lg font-medium text-foreground">
                Upload template and use it
              </h4>
              <p>
                Drag & drop or
                <span
                  @click="triggerFileInput"
                  class="font-semibold hover:underline cursor-pointer"
                  >select file</span
                >
                to import
              </p>
            </div>
          </DropZone>
          <p v-if="errorCreateTemplateDataSource" class="text-red-500 text-sm">
            {{ errorCreateTemplateDataSource }}
          </p>
        </div>
        <p class="text-xs text-placeholder text-center">
          Only one file is accepted. Allowed formats: .pdf, .docx, .pptx
        </p>
      </div>

      <p class="font-medium text-center my-4">OR</p>
    </div>

    <!-- Button instead of DropZone when isDropZone is false -->
    <div v-else class="w-full">
      <DataSourceSelectorMenuEntry
        :label="'Upload a document example'"
        :hint="'Only one document. Allowed formats: .pdf, .docx, .pptx'"
        :icon="Paperclip"
        @click="triggerFileInput"
      />
    </div>

    <!-- Hidden file input -->
    <input
      type="file"
      :accept="acceptExtensionValue"
      ref="fileInput"
      class="hidden"
      @change="onSelectFile"
    />

    <!-- Templates selector section -->
    <div class="w-full flex flex-col gap-2 items-center">
      <Select
        v-model="selectedTemplateId"
        @update:model-value="handleTemplateSelection"
      >
        <SelectTrigger
          class="py-6 px-4 border-none shadow-md rounded-md text-sm text-left text-foreground bg-background truncate hover:bg-primary10"
        >
          <div class="flex gap-2 items-center justify-start truncate">
            <div
              class="p-2 bg-primary20 group-hover:bg-primary30 rounded-full flex items-center justify-center shrink-0"
            >
              <Loader2
                v-if="
                  templateDataSourceId &&
                  templateDataSourceId.length &&
                  loadingTemplates
                "
                class="w-4 h-4 animate-spin"
              />
              <LayoutList v-else class="w-4 h-4" />
            </div>
            <SelectValue
              class="!pl-0"
              :placeholder="
                errorTemplates
                  ? 'Error loading templates'
                  : 'Select a document example'
              "
              >{{ selectedTemplateName }}</SelectValue
            >
          </div>
        </SelectTrigger>
        <SelectContent class="w-full max-h-[40vh]">
          <SelectViewport>
            <SelectItem
              v-if="!templatesList || !templatesList.length"
              :value="'no-templates'"
              disabled
            >
              No example document available
            </SelectItem>
            <template v-else>
              <SelectItem
                v-for="template in templatesList"
                :value="template.datasource_item_id!"
                :key="template.id"
                class="cursor-pointer px-2"
              >
                <div
                  class="flex gap-3 items-center"
                  :class="{ 'pl-7': !template.active }"
                >
                  <Check
                    class="h4 w-4 text-primary"
                    :id="template.id"
                    v-if="template.active"
                  />
                  {{ template.name }}
                </div>
              </SelectItem>
            </template>
          </SelectViewport>
        </SelectContent>
      </Select>
    </div>
  </div>
</template>
