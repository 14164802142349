<script setup lang="ts">
import { File, Brain, Globe, PencilLine } from "lucide-vue-next";
import AgentCard from "@/components/ChatView/guided-agent-selection/AgentCard.vue";
import { Skills } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";
import { useTenantStore } from "@/stores/tenant";
import { setUserSkill } from "@/services/skill";
import { useRoute } from "vue-router";
import { useSavedPromptStore } from "@/stores/savedPrompts";
import ChatWithDocumentsSetup from "./ChatWithDocumentsSetup.vue";
import DocumentGenerationSetup from "./DocumentGenerationSetup.vue";

import { useChatSessionsStore } from "@/stores/chatSessions";
import { ref, watch } from "vue";
import { Loader2 } from "lucide-vue-next";
import { useConfigCatStore } from "@/stores/configCat";
const toolbarStore = useToolbarStore();
const chatSessionsStore = useChatSessionsStore();
const route = useRoute();
const tenantStore = useTenantStore();
const savedPromptStore = useSavedPromptStore();
const isSkillLoading = ref(false);
const configCatStore = useConfigCatStore();
watch(
  () => toolbarStore.selectedDataSources,
  async (newValue) => {
    if (
      newValue &&
      newValue.length > 0 &&
      toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS
    ) {
      isSkillLoading.value = true;
      await markChatSessionAsVisited();
    }
  }
);

const markChatSessionAsVisited = async () => {
  const chat_session_id = route.params.session_id as string;
  await chatSessionsStore.markChatSessionAsVisited(chat_session_id);
  savedPromptStore.shouldEmptyInput = true;
  isSkillLoading.value = false;
};

async function setSelectedSkill(skill: Skills): Promise<void> {
  if (toolbarStore.selectedSkill === skill) return;

  const chat_session_id = route.params.session_id as string;
  isSkillLoading.value = true;

  toolbarStore.setSelectedSkill(skill);
  await setUserSkill(tenantStore.tenantId as string, skill, chat_session_id);

  const shouldMarkAsVisited =
    skill === Skills.CHAT_WITH_LLM || skill === Skills.CHAT_WITH_WEB;
  shouldMarkAsVisited
    ? await markChatSessionAsVisited()
    : (isSkillLoading.value = false);
}
</script>

<template>
  <div class="guided_agent h-full align-self-center flex-grow m-auto">
    <div
      class="main_loader w-full h-full flex items-center justify-center"
      v-if="isSkillLoading"
    >
      <Loader2 class="animate-spin w-6 h-6 text-grey30" />
    </div>
    <div
      class="agent_cards_container flex flex-col h-full gap-8 justify-center m-auto items-center max-w-[860px] px-8"
      v-else-if="
        toolbarStore.selectedSkill === Skills.NONE &&
        chatSessionsStore.firstVisit
      "
    >
      <div class="welcome_message font-spartan text-center">
        <h1 class="text-4xl mb-2">Welcome to Playground</h1>
        <h2 class="text-2xl font-light">How can I help you?</h2>
      </div>
      <div class="agent_cards w-full flex justify-center gap-4">
        <AgentCard
          :icon="File"
          :color="'bg-blue-100'"
          :title="'Chat with documents'"
          :text="'Chat or do complex tasks with your own documents'"
          @click="setSelectedSkill(Skills.CHAT_WITH_DOCUMENTS)"
        />
        <AgentCard
          :icon="Brain"
          :color="'bg-yellow-100'"
          :title="'Chat with ChatGPT'"
          :text="'Chat or do complex tasks with GPT'"
          @click="setSelectedSkill(Skills.CHAT_WITH_LLM)"
        />
        <AgentCard
          :icon="Globe"
          :color="'bg-green-100'"
          :title="'Chat with web'"
          :text="'Chat with web data'"
          @click="setSelectedSkill(Skills.CHAT_WITH_WEB)"
        />
        <AgentCard
          v-if="configCatStore.documentGenerator"
          :icon="PencilLine"
          :color="'bg-red-100'"
          :title="'Document content generator (Beta)'"
          :text="'Automate document content creation in few clicks'"
          @click="setSelectedSkill(Skills.DOCUMENT_GENERATOR)"
        />
      </div>
    </div>
    <ChatWithDocumentsSetup
      v-else-if="
        toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS &&
        chatSessionsStore.firstVisit
      "
      @set-selected-skill="setSelectedSkill"
    />
    <DocumentGenerationSetup
      v-else-if="
        toolbarStore.selectedSkill === Skills.DOCUMENT_GENERATOR &&
        chatSessionsStore.firstVisit
      "
      @set-selected-skill="setSelectedSkill"
    />
  </div>
</template>
