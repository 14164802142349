<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
  Trash2,
  Pencil,
  File,
  Brain,
  Globe,
  MessageSquare,
} from "lucide-vue-next";
import { SavedPrompt, Skills } from "@/types";
import { ChevronLeft, ChevronRight } from "lucide-vue-next";
import { getSkillColor } from "@/services/skill";

type Props = {
  savedPrompt: SavedPrompt;
};
const { savedPrompt } = defineProps<Props>();

const emit = defineEmits(["edit", "delete", "reorder"]);

const handleEdit = () => {
  emit("edit");
};
const handleDelete = () => {
  emit("delete");
};
const handleUpdateOrder = (upOrDown: "up" | "down") => {
  if (savedPrompt.order === null || savedPrompt.order === undefined) return;
  const newOrder =
    upOrDown === "up" ? savedPrompt.order + 1 : savedPrompt.order - 1;
  emit("reorder", { newOrder });
};
</script>

<template>
  <div
    class="prompt_card relative p-4 rounded-lg w-full h-48 flex flex-col gap-4 justify-between border border-primary30 overflow-hidden"
  >
    <div class="card_infos w-full flex flex-col gap-2">
      <div class="card_header w-full flex justify-between gap-4">
        <h2
          class="text-lg font-medium text-ellipsis overflow-hidden line-clamp-2"
        >
          {{ savedPrompt.name }}
        </h2>
        <div class="flex gap-2">
          <div
            class="h-fit flex items-center p-2 justify-center rounded-full shrink-0 text-xs group transition-all duration-500"
            :class="getSkillColor(savedPrompt.skill_name)"
          >
            <File
              v-if="savedPrompt.skill_name === Skills.CHAT_WITH_DOCUMENTS"
              class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
            />
            <Brain
              v-else-if="savedPrompt.skill_name === Skills.CHAT_WITH_LLM"
              class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
            />
            <Globe
              v-else-if="savedPrompt.skill_name === Skills.CHAT_WITH_WEB"
              class="w-4 h-4 transition-all duration-500 group-hover:-translate-x-1"
            />
            <MessageSquare v-else class="w-4 h-4" />
            <p
              class="overflow-hidden opacity-0 max-w-0 transition-all duration-500 group-hover:opacity-100 group-hover:max-w-xs whitespace-nowrap"
            >
              {{ savedPrompt.skill_name }}
            </p>
          </div>
          <p
            v-if="savedPrompt.visibility === 'private'"
            class="privacy_tag h-fit flex items-center justify-center px-2 py-1 rounded-full shrink-0 text-xs bg-gray-100"
          >
            {{ savedPrompt.visibility }}
          </p>
        </div>
      </div>
      <div class="card_content">
        <p
          class="text-muted-foreground text-sm text-ellipsis overflow-hidden line-clamp-2"
        >
          {{ savedPrompt.content }}
        </p>
      </div>
    </div>

    <div class="card_actions flex items-center justify-between gap-4">
      <div class="edit-delete-btn flex items-center gap-4">
        <Button
          @click="handleDelete"
          size="sm"
          variant="outline"
          class="flex gap-2"
          ><Trash2 class="w-4 h-4 text-forground"></Trash2> Delete</Button
        >
        <Button @click="handleEdit" size="sm" variant="dark" class="flex gap-2"
          ><Pencil class="w-4 h-4 text-background"></Pencil>
          <p>Edit</p>
        </Button>
      </div>
      <div class="order_buttons flex items-center gap-2">
        <Button
          variant="outline"
          class="p-0 aspect-square rounded-full h-6"
          @click="handleUpdateOrder('down')"
          ><ChevronLeft class="w-4 h-4"
        /></Button>
        <Button
          variant="outline"
          class="p-0 aspect-square rounded-full h-6"
          @click="handleUpdateOrder('up')"
          ><ChevronRight class="w-4 h-4"
        /></Button>
      </div>
    </div>
  </div>
</template>
